import React, { useState, useEffect } from 'react';
import { CheckCircleIcon, RocketLaunchIcon, ArrowTopRightOnSquareIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { updateCampaignStep } from '../common/Database';

export default function CampaignProgress({ onGetEmbedLink, onSetLive, campaignId, step = 2, onHide }) {
    const [isVisible, setIsVisible] = useState(step !== 6);

    useEffect(() => {
        setIsVisible(step !== 6);
    }, [step]);

    const steps = [
        { 
            name: 'Select your game type', 
            href: '/games', 
            status: step > 1 ? 'complete' : (step === 1 ? 'current' : 'upcoming') 
        },
        { 
            name: 'Build your game with the editor', 
            href: `/editor/${campaignId}`, 
            status: step > 2 ? 'complete' : (step === 2 ? 'current' : 'upcoming') 
        },
        { 
            name: 'Grab your embed link', 
            href: null, 
            status: step > 3 ? 'complete' : (step === 3 ? 'current' : 'upcoming'), 
            action: onGetEmbedLink 
        },
        { 
            name: 'Set your campaign to live and monitor performance', 
            href: null, 
            status: step > 4 ? 'complete' : (step === 4 ? 'current' : 'upcoming'), 
            action: onSetLive 
        },
    ];

    const handleHideClick = async () => {
        await updateCampaignStep(campaignId, 6);
        setIsVisible(false);
        if (onHide) {
            onHide();
        }
    };

    if (!isVisible) return null;

    return (
        <div className="relative px-4 pb-10 pt-2 sm:px-6 lg:px-8">
            <div className="bg-gray-50 rounded-lg">
                <div className="px-4 py-5 sm:p-6 flex justify-between items-start">
                    <div>
                        <div className="flex items-center text-left">
                            <RocketLaunchIcon className="h-6 w-6 text-indigo-600 mr-3" aria-hidden="true" />
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Your Journey Begins!</h3>
                        </div>
                        <p className="mt-2 text-sm text-gray-500 text-left">Follow these steps to launch your campaign successfully.</p>
                    </div>
                    <button
                        onClick={handleHideClick}
                        className="text-gray-300 hover:text-gray-400"
                    >
                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <nav className="flex justify-start px-5 sm:px-10 mt-2 pb-5" aria-label="Progress">
                    <ol role="list" className="space-y-5">
                        {steps.map((stepItem, index) => (
                            <li key={stepItem.name}>
                                {stepItem.href ? (
                                    <a href={stepItem.href} className={`group ${stepItem.status === 'current' ? 'text-indigo-600 hover:text-indigo-800' : ''}`} target={index === 1 ? "_blank" : "_self"} rel={index === 1 ? "noopener noreferrer" : ""}>
                                        <span className="flex items-start text-left">
                                            <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                                                {stepItem.status === 'complete' ? (
                                                    <CheckCircleIcon
                                                        className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center" aria-hidden="true">
                                                        {stepItem.status === 'current' ? (
                                                            <>
                                                                <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                                                                <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
                                                            </>
                                                        ) : (
                                                            <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                                                        )}
                                                    </div>
                                                )}
                                            </span>
                                            <span className={`ml-3 text-sm font-medium ${stepItem.status === 'current' ? 'text-indigo-600 group-hover:text-indigo-800' : 'text-gray-500 group-hover:text-gray-900'} flex items-center text-left`}>
                                                {stepItem.name}
                                                {index === 1 && (
                                                    <ArrowTopRightOnSquareIcon className="h-4 w-4 text-indigo-600 ml-1" aria-hidden="true" />
                                                )}
                                            </span>
                                        </span>
                                    </a>
                                ) : (
                                    <button
                                        onClick={stepItem.action}
                                        className={`group ${stepItem.status === 'current' ? 'text-indigo-600 hover:text-indigo-800' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="flex items-start text-left">
                                            <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center" aria-hidden="true">
                                                {stepItem.status === 'complete' ? (
                                                    <CheckCircleIcon
                                                        className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center" aria-hidden="true">
                                                        {stepItem.status === 'current' ? (
                                                            <>
                                                                <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                                                                <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
                                                            </>
                                                        ) : (
                                                            <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <p className={`ml-3 text-sm font-medium ${stepItem.status === 'current' ? 'text-indigo-600 group-hover:text-indigo-800' : 'text-gray-500 group-hover:text-gray-900'} flex items-center text-left`}>
                                                {stepItem.name}
                                            </p>
                                        </div>
                                    </button>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    );
}