import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/logo.png";
import AlertError from "./PermissionDenied";
import PasswordResetModal from "./PasswordReset";

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notice, setNotice] = useState("");
    const [showResetModal, setShowResetModal] = useState(false);

       // Added useEffect hook to check if the user is already logged in
       useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if (user.emailVerified) {
                    navigate("/");
                } else {
                    navigate("/verify");
                }
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        setNotice("");
    }, [email, password]);

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/user-not-found':
                return 'No user found with this email.';
            case 'auth/wrong-password':
                return 'The password is invalid.';
            case 'auth/user-disabled':
                return 'The user account has been disabled by an administrator.';
            default:
                return 'Invalid email or password. Please try again.';
        }
    };

    const loginWithUsernameAndPassword = async (e) => {
        e.preventDefault();

        try {
            const response = await signInWithEmailAndPassword(auth, email, password);
            const user = response.user;

            if (user.emailVerified) {
                sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
                navigate("/");
            } else {
                // Redirect to verification page if the user is not verified
                navigate("/verify");
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error.code);
            setNotice(errorMessage);
            console.error("Error signing in: ", error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    alt="Your Company"
                    src={Logo}
                    className="mx-auto h-10 w-auto"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={loginWithUsernameAndPassword} method="POST" className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="text-sm">
                                <button
                                    type="button"
                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    onClick={() => setShowResetModal(true)}
                                >
                                    Forgot password?
                                </button>
                            </div>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {notice && 
                        <AlertError text={notice} dashboardButton={false}/>
                    }

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sign in
                        </button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?{' '}
                    <Link to="/register" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                        Get started for free
                    </Link>
                </p>
            </div>

            <PasswordResetModal show={showResetModal} onClose={() => setShowResetModal(false)} auth={auth} />
        </div>
    );
}