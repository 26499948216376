import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './userContext'; // Adjust the import path based on where you define the UserContext
import DefaultLoading from './common/DefaultLoading'; // Adjust the import path based on your actual loading component

const PrivateRoute = ({ children }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) {
    return <DefaultLoading />; // Show loading indicator while checking auth status
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (user && !user.emailVerified) {
    return <Navigate to="/verify"/>;
  }

  return children;
};

export default PrivateRoute;