import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronRightIcon,
  PlayIcon,
  CursorArrowRaysIcon,
  ShareIcon,
  ClockIcon,
} from '@heroicons/react/20/solid';

import {statuses} from '../data/StatusColours'

const statIcons = {
  totalPlayCount: PlayIcon,
  totalShared: ShareIcon,
  totalCta: CursorArrowRaysIcon,
  averageGameTime: ClockIcon,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function List({ records, paddingTop = 'pt-7' }) {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const formatAdditionalItems = (items) => {
    return items.map((item, index) => (
      <span key={index} className="block sm:inline">
        {item.text}: {item.value}
      </span>
    ));
  };

  const formatStats = (stats) => {
    return (
      <div className="flex flex-wrap items-center gap-x-2">
        {Object.keys(stats).map((key, index) => {
          const Icon = statIcons[key];
          return (
            <div key={index} className="flex items-center gap-x-1 mr-2 mt-2">
              {Icon && <Icon className="h-3 w-3 text-gray-500 " aria-hidden="true" />}
              <span>{stats[key]}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classNames("bg-white overflow-hidden", paddingTop)}>
      <ul role="list" className="divide-y divide-gray-100 overflow-hidden shadow-sm ring-1 ring-gray-900/5">
        {records.map((record, index) => (
          <li key={record.id} className={classNames(
            'relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6',
            index === records.length - 1 ? 'border-b border-gray-100' : ''
          )}>
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900 flex items-center">
                  <span 
                    className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap" 
                    onClick={() => handleNavigate(`/campaign/${record.id}`)}
                  >
                    <span className={classNames(
                      statuses[record.status],
                      'inline-block mr-2 rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset sm:hidden capitalize'
                    )}>
                      {record.status}
                    </span>
                    <span className="absolute inset-x-0 -top-px bottom-0" />
                    {record.name}
                  </span>
                </p>
                <p className="mt-1 flex text-xs leading-5 text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">{record.options.title}</p>
                {record.additionalItems && record.additionalItems.length > 0 && (
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    {formatAdditionalItems(record.additionalItems)}
                  </p>
                )}
                {record.stats && (
                  <div className="mt-1 text-xs leading-5 text-gray-500">
                    {formatStats(record.stats)}
                  </div>
                )}
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4">
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <p className={classNames(
                  statuses[record.status],
                  'whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset capitalize'
                )}>
                  {record.status}
                </p>
                <p className="mt-1 text-xs leading-6 text-gray-500 capitalize overflow-hidden text-ellipsis whitespace-nowrap">{record.gameTypeDisplayValue}</p>
              </div>
              <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default List;