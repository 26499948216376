import { useNavigate } from 'react-router-dom';
import Loading from "../common/DefaultLoading";
import useFetchCampaigns from './useFetchCampaigns'; // Adjust the path as needed

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function GamesList({ queryString = [], searchQuery = '' }) {
  const { campaigns, isLoading } = useFetchCampaigns(queryString);
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  // Normalize search query for case-insensitive comparison
  const normalizedSearchQuery = searchQuery.trim().toLowerCase();

  // Filter campaigns based on the presence of searchQuery
  const filteredCampaigns = campaigns.filter(campaign => {
    const isFeatured = campaign.featured === true;
    const isNotFeatured = campaign.featured !== true;

    // Normalize campaign title and intro for case-insensitive comparison
    const normalizedTitle = campaign.options.title?.toLowerCase() || '';
    const normalizedIntro = campaign.options.intro?.toLowerCase() || '';

    const matchesSearchQuery =
      normalizedTitle.includes(normalizedSearchQuery) ||
      normalizedIntro.includes(normalizedSearchQuery);

    // Include both featured and non-featured if searchQuery is present
    if (normalizedSearchQuery) {
      return matchesSearchQuery;
    }

    // Otherwise, exclude featured campaigns
    return isNotFeatured;
  });

  // Check if there are no results
  if (filteredCampaigns.length === 0) {
    return (
      <div className="text text-gray-500">
        <p>No results found for '{searchQuery}'</p>
      </div>
    );
  }

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {filteredCampaigns.map((campaign) => (
        <li key={campaign.id} className="flex justify-between gap-x-6">
          <a 
            href={`/play/${campaign.id}`} 
            target="_blank" 
            className="flex w-full items-center justify-between gap-x-6 py-5 no-underline "
          >
            <div className="flex min-w-0 gap-x-4">
              <img 
                alt={campaign.options.title} 
                src={campaign.options.sharingPhoto || campaign.options.coverPhoto || "https://www.smilesflow.com/twitter.png"} 
                className="h-12 w-12 flex-none rounded bg-gray-50 object-cover" 
              />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900 truncate w-full">{campaign.options.title}</p>
                <p className='text-xs'>{campaign.gameTypeDisplayValue}</p>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">{campaign.role}</p>
              <div className="mt-1 flex items-center gap-x-1.5">
                <p className="text-xs leading-5 text-gray-500">Play now</p>
              </div>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
}