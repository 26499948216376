import { useNavigate } from 'react-router-dom';
import { PlayIcon } from '@heroicons/react/20/solid';
import Loading from "../common/DefaultLoading";
import useFetchCampaigns from './useFetchCampaigns'; // Adjust the path as needed

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DisplayGames({ queryString = [] }) {
  const { campaigns, isLoading } = useFetchCampaigns(queryString);
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      {campaigns.map((campaign) => (
        <article
          key={campaign.id}
          className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
        >
          <img
            alt=""
            src={campaign.options.sharingPhoto || campaign.options.coverPhoto || "https://www.smilesflow.com/twitter.png"}
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
          <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

          <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
            <div className="flex gap-x-2.5">
              {campaign.gameTypeDisplayValue}
            </div>
          </div>
          <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
            <a href={`/play/${campaign.id}`} target="_blank">
              <span className="absolute inset-0" />
              {campaign.options.title}
            </a>
          </h3>
        </article>
      ))}
    </div>
  );
}