import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { sendEmailVerification, signOut, onAuthStateChanged } from 'firebase/auth';
import { ExclamationCircleIcon, CheckCircleIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import Logo from "../images/logo.png";
import DefaultLoading from '../common/DefaultLoading';

const Verify = () => {
    const navigate = useNavigate();
    const [verificationNotice, setVerificationNotice] = useState("");
    const [verificationNoticeType, setVerificationNoticeType] = useState(""); // "success" or "error"
    const [showVerificationButton, setShowVerificationButton] = useState(true);
    const [isVerificationButtonDisabled, setIsVerificationButtonDisabled] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await user.reload();
                if (user.emailVerified) {
                    setIsVerified(true);
                    navigate("/");
                } else {
                    setIsVerified(false);
                    setIsLoading(false);
                }
            } else {
                navigate("/login");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const maxIntervalDuration = 300000; // 5 minutes in milliseconds
        const intervalDuration = 3000; // 3 seconds

        const interval = setInterval(() => {
            const user = auth.currentUser;
            if (user) {
                user.reload().then(() => {
                    if (user.emailVerified) {
                        setIsVerified(true);
                        clearInterval(interval);
                        navigate("/plan");
                    }
                });
            }
        }, intervalDuration);

        const timeout = setTimeout(() => {
            clearInterval(interval);
        }, maxIntervalDuration);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [navigate]);

    const handleSendVerificationEmail = async () => {
        console.log("handleSendVerificationEmail called");
        try {
            const user = auth.currentUser;
            if (user) {
                await sendEmailVerification(user);
                console.log("Verification email sent");
                setVerificationNotice("Verification email sent. Please check your inbox.");
                setVerificationNoticeType("success");
                setShowVerificationButton(false);
                setIsVerificationButtonDisabled(true);

                // Re-enable the button after 2 minutes
                setTimeout(() => {
                    setVerificationNotice("");
                    setVerificationNoticeType("");
                    setIsVerificationButtonDisabled(false);
                    setShowVerificationButton(true);
                }, 120000);
            }
        } catch (error) {
            let errorMessage = "Error sending verification email. Please try again later.";
            switch (error.code) {
                case 'auth/too-many-requests':
                    errorMessage = "Too many requests. Please wait before trying again.";
                    break;
                case 'auth/invalid-email':
                    errorMessage = "The provided email is not valid.";
                    break;
                case 'auth/user-not-found':
                    errorMessage = "No user found with this email.";
                    break;
                default:
                    console.error("Error sending verification email: ", error);
            }
            setVerificationNotice(errorMessage);
            setVerificationNoticeType("error");
            setShowVerificationButton(false);
            setIsVerificationButtonDisabled(true);

            // Hide the verification message and re-enable the button after 2 minutes
            setTimeout(() => {
                setVerificationNotice("");
                setVerificationNoticeType("");
                setIsVerificationButtonDisabled(false);
                setShowVerificationButton(true);
            }, 120000);
        }
    };

    const handleSignInWithDifferentAccount = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    if (isLoading) {
        return (
            <DefaultLoading />
        );
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    alt="SmilesFlow"
                    src={Logo}
                    className="mx-auto h-10 w-auto"
                />
                <EnvelopeIcon className="mx-auto mt-10 h-10 w-10 text-indigo-600 animate-bounce" />
                <h2 className="mt-5 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Verify email
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                To access your account, please verify your email address. Check your inbox or spam folder for a verification email.
                                </p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {verificationNotice && (
                    <div className={`mt-2 text-sm ${verificationNoticeType === "error" ? 'text-red-600' : 'text-green-600'} flex items-center`}>
                        {verificationNoticeType === "error" ? (
                            <ExclamationCircleIcon className="h-5 w-5 mr-2" />
                        ) : (
                            <CheckCircleIcon className="h-5 w-5 mr-2" />
                        )}
                        {verificationNotice}
                    </div>
                )}
                {showVerificationButton && (
                    <div className="mt-4 flex justify-center">
                        <button
                            type="button"
                            className="w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={handleSendVerificationEmail}
                            disabled={isVerificationButtonDisabled}
                        >
                            {isVerificationButtonDisabled ? "Please wait..." : "Resend Verification Email"}
                        </button>
                    </div>
                )}
                <div className="mt-4 flex justify-center">
                    <button
                        type="button"
                        className="w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSignInWithDifferentAccount}
                    >
                        Sign in with a different account
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Verify;