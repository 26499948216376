import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import React from 'react';

const HeadlineCard = ({ url, photoUrl, headline, subheadline, options, preview = false }) => {
  if (!options.displayHeadline) {
    return null; // Don't render the component if displayHeadline is false
  }

  const defaultPhotoUrl = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'; // Replace with your default image URL

  const formattedUrl = url && !url.startsWith('http') ? `http://${url}` : url;

  const Container = url ? 'a' : 'div';
  const containerProps = url
    ? {
        href: formattedUrl,
        target: "_blank",
        rel: "noopener noreferrer",
        className: `group block flex-shrink-0 ${preview ? '' : 'pb-7'}`,
      }
    : {
        className: `block flex-shrink-0 ${preview ? '' : 'pb-7'}`,
      };

  return (
    <Container {...containerProps}>
      <div className="flex items-center">
        <div>
          <img
            className="inline-block h-9 w-9 rounded-full"
            src={photoUrl || defaultPhotoUrl}
            alt="Profile"
          />
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${url ? 'text-gray-700 group-hover:text-gray-900' : 'text-gray-700'}`}>
            {headline}
          </p>
          <p className={`text-xs font-medium flex items-center ${url ? 'text-gray-500 group-hover:text-gray-700' : 'text-gray-500'}`}>
            {subheadline}
            {url && (
              <ArrowTopRightOnSquareIcon className="ml-1 h-3 w-3 text-gray-500 group-hover:text-gray-700" aria-hidden="true" />
            )}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default HeadlineCard;