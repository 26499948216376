import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import Layout from './Layout';
import Login from './dashboard/Login';
import Register from './dashboard/Signup';
import Profile from './dashboard/Profile';
import Campaigns from './dashboard/Campaigns';
import Campaign from './dashboard/Campaign';
import Examples from './dashboard/Examples';
import Play from './games/Play';
import Editor from './games/Editor';
import { UserProvider } from './userContext';
import PrivateRoute from './PrivateRoute';
import Support from './dashboard/Support';
import SharedByUsers from './dashboard/SharedByUsers';
import ScrollToTop from './dashboard/ScrollToTop';
import PlanModal from './dashboard/PlanModal';
import Verify from './dashboard/Verify';
import GameCenter from './GameCenter/GameCenterHome';


const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <RoutesWrapper />
      </UserProvider>
    </BrowserRouter>
  );
};

const RoutesWrapper = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="verify" element={<Verify />} />
        <Route path="play/:id" element={<Play />} />
        <Route
          path="editor/:id"
          element={
            <UserProvider>
              <Editor />
            </UserProvider>
          }
        />

<Route path="playground" element={<GameCenter />} /> {}

        <Route
          path="/"
          element={
            <UserProvider>
              <Layout />
            </UserProvider>
          }
        >
          <Route index element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="plan" element={<PrivateRoute><PlanModal /></PrivateRoute>} />
          <Route path="support" element={<PrivateRoute><Support /></PrivateRoute>} />
          <Route path="profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="campaigns" element={<PrivateRoute><Campaigns /></PrivateRoute>} />
          <Route path="campaign/:id" element={<PrivateRoute><Campaign /></PrivateRoute>} />
          <Route path="games" element={<Examples />} /> {/* Make game library public */}
          <Route path="shared" element={<PrivateRoute><SharedByUsers /></PrivateRoute>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;