import { GlobeAltIcon, MapIcon, MapPinIcon, ListBulletIcon, AcademicCapIcon } from '@heroicons/react/24/outline';

export const gameTypes = [
  { 
    id: "pindrop",
    name: 'Pin-Drop',
    description: 'Drop a pin as close to the specified locations as possible.',
    aiOutline:"Famous landmarks in major cities around the world, such as the Eiffel Tower, Statue of Liberty, and Great Wall of China.",
    icon: MapPinIcon, // Use Heroicon here
    role: "free",
    exampleId: "62cmkStzelwwGovG9Fal",
    exampleName: "Global Landmark Adventure: Can You Pin 'Em All?",
    imageUrl: '/GameTypeImages/pindrop.png',
    additionalImages: [
      '/GameTypeImages/pindropmid.png',
      '/GameTypeImages/pindropend.png'
    ],
    options: {
      title: "Welcome to your new Pin-Drop campaign!",
      intro: "Get started by opening the campaign editor using the actions button",
      ctaEnabled: false,
      colorButton: "#4F46E5",
      start_view: {
        lat: 54.5,
        lng: -2,
        zoom: 3
      },
      start_radius: 2,
      zoom_level: 17,
      mapType: "roadmap",
      data: [{
        id: "1",
        lat: 48.85802422036538,
        lng: 2.294508122090164,
        name: "Eiffel tower",
        zoom: 17
      }]
    },
  },

  {
    "id": "hint",
    "name": "Hint-Guess",
    "description": "Guess the answer based on hints. Each guess or skip reveals a new hint.",
    "aiOutline": "A difficult game based on Z-list celebrities...",
    "icon": ListBulletIcon,
    "role": "free",
    "exampleId": "gFSPj1NRn3eMfEmRn1ZR",
    "exampleName": "Step into History: The Ultimate Figure Challenge!",
    "imageUrl": "/GameTypeImages/hintgame.png",
    "additionalImages": [
      "/GameTypeImages/hintend.png"
    ],
    "options": {
      "title": "Welcome to your new Hint-Guess campaign!",
      "intro": "Guess the answer based on the hints provided. You have up to 6 hints to figure it out.",
      "ctaEnabled": false,
      "colorButton": "#4F46E5",
      "backgroundImage": "", // Optional URL for a background image
      "data": [
        {
          "id": "1",
          "answer": "Eiffel Tower",
          "hints": [
            "It is located in Paris.",
            "This landmark was completed in 1889.",
            "It is named after the engineer who designed it.",
            "It is one of the most recognizable structures in the world.",
            "It was initially criticized by some of France's leading artists and intellectuals.",
            "It stands at 324 meters tall."
          ]
        }
      ],
      "dummyData": [
        "Empire State Building",
        "Big Ben",
        "Burj Khalifa"
      ]
    },
  },
  { 
    id: "quiz",
    name: 'Quiz',
    description: 'Answer questions in a multiple choice format.',
    aiOutline:'Famous historical events, world capitals, or popular science facts.',
    icon: AcademicCapIcon, // Use Heroicon here
    role: "free",
    exampleId: "FPsVoKVOWXUmL1bKNsZE",
    exampleName: "Journey Through Famous Historical Events",
    imageUrl: '/GameTypeImages/quiz.png',
    additionalImages: [
      '/GameTypeImages/quizend.png'
    ],
    options: {
      title: "Welcome to your new Quiz-Style campaign!",
      intro: "Get started by opening the campaign editor using the actions button",
      ctaEnabled: false,
      colorButton: "#4F46E5",
      backgroundImage: "", // Optional URL for a background image
      data: [
        {
          id: "1",
          question: "What is the capital of France?",
          options: [
            { text: "Paris", correct: true },
            { text: "London", correct: false },
            { text: "Rome", correct: false },
            { text: "Berlin", correct: false }
          ],
          hint: ""
        },
        {
          id: "2",
          question: "Which landmark is located in New York?",
          options: [
            { text: "Statue of Liberty", correct: true },
            { text: "Eiffel Tower", correct: false },
            { text: "Taj Mahal", correct: false },
            { text: "Sydney Opera House", correct: false }
          ],
          hint: ""
        }
        // Additional questions up to 10 entries...
      ]
    }
    },
  { 
    id: "mapselect",
    name: 'Map-Select',
    description: 'Type and select an answer based on the location displayed.',
    aiOutline:"Famous landmarks in major cities around the world, such as the Eiffel Tower, Statue of Liberty, and Great Wall of China.",
    icon: MapIcon, // Use Heroicon here
    role: "free",
    exampleId: "DJJ96OosfG0GpUahlV8W",
    exampleName: "Journey Through Landmarks: A Global Scavenger Quest",
    imageUrl: '/GameTypeImages/mapselect.png',
    additionalImages: [
      '/GameTypeImages/mapmultiend.png'
    ],
    options: {
      title: "Welcome to your new Map-Select campaign!",
      intro: "Get started by opening the campaign editor using the actions button",
      ctaEnabled: false,
      movement: true,
      colorButton: "#4F46E5",
      mapType: "satellite",
      data: [
        {
          id: "1",
          lat: 40.689247,
          lng: -74.044502,
          name: "Statue of Liberty",
          zoom: 18
        },
        {
          id: "2",
          lat: 48.85802422036538,
          lng: 2.294508122090164,
          name: "Eiffel Tower",
          zoom: 17
        },
        {
          id: "3",
          lat: 27.1751448,
          lng: 78.0421422,
          name: "Taj Mahal",
          zoom: 17
        },
        {
          id: "4",
          lat: -33.8567844,
          lng: 151.2152967,
          name: "Sydney Opera House",
          zoom: 17
        }
      ]
    },
  },
  { 
    id: "mapmulti",
    name: 'Map-Multi',
    description: 'Multiple choice based on the location displayed.',
    aiOutline:"Famous landmarks in major cities around the world, such as the Eiffel Tower, Statue of Liberty, and Great Wall of China.",
    icon: GlobeAltIcon, // Use Heroicon here
    role: "free",
    exampleId: "gfNCdXfeWQVgN09JJGUz",
    exampleName: "World Landmark Explorer: Unravel the globe's wonders!",
    imageUrl: '/GameTypeImages/mapmulti.png',
    additionalImages: [
      '/GameTypeImages/mapmultimid.png',
      '/GameTypeImages/mapmultiend.png'
    ],
    options: {
      title: "Welcome to your new Map-Multi campaign!",
      intro: "Get started by opening the campaign editor using the actions button",
      ctaEnabled: false,
      movement: true,
      colorButton: "#4F46E5",
      mapType: "satellite",
      data: [
        {
          id: "1",
          lat: 40.689247,
          lng: -74.044502,
          name: "Statue of Liberty",
          zoom: 18
        },
        {
          id: "2",
          lat: 48.85802422036538,
          lng: 2.294508122090164,
          name: "Eiffel Tower",
          zoom: 17
        },
        {
          id: "3",
          lat: 27.1751448,
          lng: 78.0421422,
          name: "Taj Mahal",
          zoom: 17
        },
        {
          id: "4",
          lat: -33.8567844,
          lng: 151.2152967,
          name: "Sydney Opera House",
          zoom: 17
        }
      ]
    },
  },

];

