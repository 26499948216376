import { PlayIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

export default function CampaignEmptyState({ header, icon: Icon, action, actionLink, actionFunction }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (actionFunction) {
      actionFunction();
    } else if (actionLink) {
      navigate(actionLink);
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className="relative block w-full border-t border-b border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      {Icon && <Icon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />}
      <span className="mt-2 block text-sm  text-gray-400">{header}</span>
      {action && <span className="mt-2 block text-sm font-semibold text-gray-500">{action}</span>}
    </button>
  );
}