import { useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  CalendarIcon,
  QuestionMarkCircleIcon,
  PuzzlePieceIcon,
  ListBulletIcon,
  DocumentTextIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ArrowTopRightOnSquareIcon
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useNavigate, Outlet, useLocation } from 'react-router-dom'; // Import useLocation
import { auth } from './firebase';  // Import the auth object
import Logo from './images/bluelogo.png';
import PlanLevel from './dashboard/PlanLevel';
import { useUser } from './userContext'; // Adjust the import path based on where you define the UserContext
import Search from './dashboard/Search';



const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Campaigns', href: '/campaigns', icon: ListBulletIcon },
  { name: 'Game Library', href: '/games', icon: PuzzlePieceIcon },
];
const teams = [
  { id: 1, name: 'Playground', href: '/playground', initial: 'P', openInNewTab: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, currentPlan, isFreeUser, firstName } = useUser();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  

  const handleSignOut = () => {
    auth.signOut().then(() => {
      navigate("/login");
    }).catch((error) => {
      console.error("Error signing out", error);
    });
  };

  const userNavigation = [


    { name: 'My account', href: '/profile' },
    { name: 'Sign out', onClick: handleSignOut},
  ]

  const closeSidebarAndNavigate = (href) => {
    setSidebarOpen(false);
    navigate(href);
  };

  return (
    <>
    
      <div>
        <Dialog className="relative z-50 lg:hidden" open={sidebarOpen} onClose={setSidebarOpen}>
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    className="h-8 w-auto cursor-pointer"
                    src={Logo}
                    alt="SmilesFlow"
                    onClick={() => navigate('/')} // Add the navigate functionality here
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
  <li key={item.name}>
    <button
      onClick={() => closeSidebarAndNavigate(item.href)}
      className={classNames(
        location.pathname === item.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full'
      )}
    >
      <item.icon
        className={classNames(
          location.pathname === item.href ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
          'h-6 w-6 shrink-0'
        )}
        aria-hidden="true"
      />
      {item.name}
    </button>
  </li>
))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">Explore Resources</div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
           
                      {teams.map((team) => (
  <li key={team.name} className="flex items-center justify-between">
    {team.openInNewTab ? (
      <a
        href={team.href}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          location.pathname === team.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full'
        )}
      >
        <span
          className={classNames(
            location.pathname === team.href ? 'border-indigo-600 text-indigo-600' : 'border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600',
            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium'
          )}
        >
          {team.initial}
        </span>
        <span className="flex items-center truncate">
          {team.name}
          <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4" aria-hidden="true" />
        </span>
      </a>
    ) : (
      <button
        onClick={() => closeSidebarAndNavigate(team.href)}
        className={classNames(
          location.pathname === team.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full'
        )}
      >
        <span
          className={classNames(
            location.pathname === team.href ? 'border-indigo-600 text-indigo-600' : 'border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600',
            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium'
          )}
        >
          {team.initial}
        </span>
        <span className="flex items-center truncate">
          {team.name}
        </span>
      </button>
    )}
  </li>
))}
       
                      </ul>
                    </li>
                {currentPlan && (
        <li className="mt-auto">
<PlanLevel plan={currentPlan} onLinkClick={closeSidebarAndNavigate} />
        </li>
      )}
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto cursor-pointer"
                src={Logo}
                alt="SmilesFlow"
                onClick={() => navigate('/')} // Add the navigate functionality here

              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
  <li key={item.name}>
    <button
      onClick={() => closeSidebarAndNavigate(item.href)}
      className={classNames(
        location.pathname === item.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full'
      )}
    >
      <item.icon
        className={classNames(
          location.pathname === item.href ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
          'h-6 w-6 shrink-0'
        )}
        aria-hidden="true"
      />
      {item.name}
    </button>
  </li>
))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Explore Resources</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
             
                  {teams.map((team) => (
  <li key={team.name} className="flex items-center justify-between">
    {team.openInNewTab ? (
      <a
        href={team.href}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          location.pathname === team.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full'
        )}
      >
        <span
          className={classNames(
            location.pathname === team.href ? 'border-indigo-600 text-indigo-600' : 'border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600',
            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium'
          )}
        >
          {team.initial}
        </span>
        <span className="flex items-center truncate">
          {team.name}
          <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4" aria-hidden="true" />
        </span>
      </a>
    ) : (
      <button
        onClick={() => closeSidebarAndNavigate(team.href)}
        className={classNames(
          location.pathname === team.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full'
        )}
      >
        <span
          className={classNames(
            location.pathname === team.href ? 'border-indigo-600 text-indigo-600' : 'border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600',
            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium'
          )}
        >
          {team.initial}
        </span>
        <span className="flex items-center truncate">
          {team.name}
        </span>
      </button>
    )}
  </li>
))}
                  </ul>
                </li>
                {currentPlan && (
        <li className="mt-auto">
<PlanLevel plan={currentPlan} onLinkClick={closeSidebarAndNavigate} />
        </li>
      )}
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
  <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 lg:px-8">
    <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
      <span className="sr-only">Open sidebar</span>
      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
    </button>

    {/* Separator */}
    <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

    <div className="flex flex-1 gap-x-4 self-stretch items-center lg:gap-x-6 relative">
      <Search />
      <div className="flex items-center gap-x-4 lg:gap-x-6">

        {/* Separator */}
        <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

        {user && (
          <Menu as="div" className="relative">
            <MenuButton className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <div className="flex items-center justify-center h-8 w-8 rounded-full bg-gray-50 text-gray-900">
                {firstName ? firstName.charAt(0).toUpperCase() : ''}
              </div>
              <span className="hidden lg:flex lg:items-center">
                <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                  {firstName || ''}
                </span>
                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {userNavigation.map((item) => (
                <MenuItem key={item.name}>
                  {({ focus }) => (
                    item.onClick ? (
                      <button
                        onClick={item.onClick}
                        className={classNames(
                          focus ? 'bg-gray-50' : '',
                          'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        {item.name}
                      </button>
                    ) : (
                      <button
                        onClick={() => closeSidebarAndNavigate(item.href)}
                        className={classNames(
                          focus ? 'bg-gray-50' : '',
                          'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        {item.name}
                      </button>
                    )
                  )}
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        )}
      </div>
    </div>
  </div>

  <main>
    <Outlet />
  </main>
</div>
      </div>

    </>
  )
}