import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { db } from "../firebase"; // Ensure correct path to firebaseConfig.js
import { loadStripe } from "@stripe/stripe-js";
import { collection, addDoc, onSnapshot } from "firebase/firestore";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutButton = forwardRef(({ uid, priceId }, ref) => {
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);

  const createCheckoutSession = async () => {
    try {
      console.log("Creating checkout session...");
      const checkoutSessionsRef = collection(db, "users", uid, "checkout_sessions");
      const checkoutSessionRef = await addDoc(checkoutSessionsRef, {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: `${window.location.origin}/plan`
      });

      console.log("Checkout session created:", checkoutSessionRef.id);

      const unsubscribe = onSnapshot(checkoutSessionRef, (snap) => {
        const data = snap.data();
        if (data && data.sessionId) {
          console.log("Session ID received inside onSnapshot:", data.sessionId);
          setCheckoutSessionId(data.sessionId);
        }
      });

      // Clean up the subscription on unmount
      return () => unsubscribe();
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  useImperativeHandle(ref, () => ({
    createCheckoutSession,
  }));

  useEffect(() => {
    const redirectToCheckout = async () => {
      if (checkoutSessionId) {
        try {
          console.log("Redirecting to Stripe checkout with session ID:", checkoutSessionId);
          const stripe = await stripePromise;
          if (stripe) {
            console.log("Stripe instance is available, proceeding to redirect...");
            await stripe.redirectToCheckout({ sessionId: checkoutSessionId });
          } else {
            console.error("Stripe instance not available");
          }
        } catch (error) {
          console.error("Error redirecting to Stripe checkout:", error);
        }
      }
    };

    redirectToCheckout();
  }, [checkoutSessionId]);

  return null;
});

export default CheckoutButton;