import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../firebase'; // Adjust the import path as needed
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const FileUpload = ({ onSuccess, onDelete, field, options, displayText, displayPreview, subtitle = null }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState(options?.[field] || '');
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (options?.[field]) {
      setCurrentFileUrl(options[field]);
    }
  }, [options, field]);

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // File type restriction
    const allowedTypes = ['image/jpeg', 'image/png'];
    if (!allowedTypes.includes(selectedFile.type)) {
      setErrorMessage('Invalid file type. Only JPG and PNG are allowed.');
      return;
    }

    // File size restriction
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeInBytes) {
      setErrorMessage('File size exceeds the 10MB limit.');
      return;
    }

    setErrorMessage('');
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (!file) return;

    setUploading(true);
    const storageReference = storageRef(storage, `${field}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageReference, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(prog);
      },
      (error) => {
        console.log(error);
        setUploading(false);
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('File available at', url); // Logging the URL
        setCurrentFileUrl(url);
        handleFileUploadSuccess(url);
        setUploading(false);
      }
    );
  };

  const handleFileUploadSuccess = (url) => {
    const updatedOptions = { ...options, [field]: url };
    onSuccess(updatedOptions);
  };

  const handleDelete = () => {
    setCurrentFileUrl(null);
    setProgress(0);
    fileInputRef.current.value = null; // Reset the input field
    const updatedOptions = { ...options, [field]: null };
    onDelete(updatedOptions);
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center justify-between w-full">
        <span className="flex flex-grow flex-col">
          <span className="text-sm font-medium leading-6 text-gray-900">
            {displayText}
          </span>
          {subtitle && (
            <span className="text-sm text-gray-500">
              {subtitle}
            </span>
          )}
        </span>
        {currentFileUrl ? (
          <div className="flex gap-2">
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => fileInputRef.current.click()}
            >
              <PencilIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
              onClick={handleDelete}
            >
              <TrashIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center gap-2"
            onClick={() => fileInputRef.current.click()}
          >
            Upload
          </button>
        )}
        <input
          id={`${field}-upload`}
          name={`${field}-upload`}
          type="file"
          className="sr-only"
          ref={fileInputRef}
          onChange={handleChange}
        />
      </div>
      {uploading && (
        <div className="w-full bg-gray-200 rounded-full h-1.5 mt-2">
          <div
            className="bg-blue-600 h-1.5 rounded-full transition-all duration-500 ease-in-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
      {currentFileUrl && displayPreview && (
        <div className="mt-4 flex justify-center">
          <img
            src={currentFileUrl}
            alt="Uploaded file"
            className={classNames(
              "object-cover rounded",
              field === 'photo' ? 'max-h-10' : 'max-h-40'
            )}
          />
        </div>
      )}
      {errorMessage && (
        <div className="mt-2 text-sm text-red-600">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default FileUpload;