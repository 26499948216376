import React from 'react';
import { PencilSquareIcon, CodeBracketSquareIcon, CheckCircleIcon, HeartIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';

// Helper function to combine class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Actions({ actions, singleColumn }) {
  return (
    <div className={classNames(
      'divide-y divide-gray-100 overflow-hidden bg-gray-200',
      singleColumn ? 'grid grid-cols-1' : 'sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0'
    )}>
      {actions.map((action, actionIdx) => {
        const Icon = action.icon;
        const TopRightIcon = action.topRightIcon || CheckCircleIcon; // Default to CheckCircleIcon if not provided
        const isSingleColumn = actions.length === 3 && !singleColumn;
        const isLastAction = actionIdx === actions.length - 1;

        return (
          <div
            key={action.name}
            className={classNames(
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
              isSingleColumn && isLastAction ? 'col-span-2' : '' // Stretch the last action if necessary
            )}
          >
            <div>
              <span
                className={classNames(
                  'inline-flex ring-4 ring-white',
                  action.iconBackground,
                  action.iconForeground
                )}
              >
                {Icon && <Icon className="h-6 w-6" aria-hidden="true" />}
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {action.link ? (
                <a
                href={action.link}
                className="focus:outline-none"
              >
                <span className="absolute inset-0" aria-hidden="true" />
                {action.name}
              </a>
                ) : (
                  <button onClick={action.action} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.name}
                  </button>
                )}
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.subheader || 'Description for this action'}
              </p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <TopRightIcon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default function ActionsCard({ actions, singleColumn }) {
  return (
    <div className="divide-y divide-gray-100 overflow-hidden bg-white border-b border-gray-200">
      <div className="">
        <Actions actions={actions} singleColumn={singleColumn} />
      </div>
    </div>
  );
}