import React, { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import your Firestore configuration
import DefaultLoading from '../common/DefaultLoading';

const Play = () => {
  const { id } = useParams();
  const [gameData, setGameData] = useState(null); // State to manage game data
  const [GameComponent, setGameComponent] = useState(null);
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const loadGameComponent = async () => {
      const docRef = doc(db, "campaign", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Document exists
        const foundDoc = docSnap.data();
        setGameData(foundDoc); // Store the game data
        const gameType = foundDoc.gameType; // Assuming `gameType` is a field in your document

        // Dynamically import the game component based on the game type
        try {
          const importedComponent = React.lazy(() =>
            import(`../games/${gameType}/Main`)
          );
          setGameComponent(importedComponent);
        } catch (error) {
          console.error("Failed to load the game component", error);
          // Handle error or set a fallback component
        }
      } else {
        // No such document
        console.log("No such document!");
        // Handle this scenario, perhaps by setting a default component or showing an error message
      }
      setLoading(false); // Set loading to false once the component is loaded or failed to load
    };

    loadGameComponent();
  }, [id]);

  if (loading) {
    return <DefaultLoading />;
  }

  return (
    <Suspense fallback={<DefaultLoading />}>
      {GameComponent ? <GameComponent /> : <div>Game not found.</div>}
    </Suspense>
  );
};

export default Play;