import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { doc, getDoc, setDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';
import useAuth from './useAuth';
import Loading from "../common/DefaultLoading";
import Stats from "./stats";
import Slideover from "./Slideover";
import UpdateCampaignName from "./updateCampaignName"; 
import CampaignEmbed from "./CampaignEmbed";
import AlertComponent from "./DialogConfirm";
import Actions from '../dashboard/actions';
import CampaignBanner from '../dashboard/CampaignBanner';
import UpdateCampaignStatus from './updateCampaignStatus';
import AlertError from "./PermissionDenied";
import CampaignProgress from './CampaignProgress';
import {
  PencilSquareIcon,
  CodeBracketSquareIcon,
  ArrowTopRightOnSquareIcon,
  ArrowLeftIcon,
  ShareIcon,
  MinusCircleIcon,
  PlayIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';

export default function EditCampaign() {
  const { user, loading } = useAuth();
  const [campaign, setCampaign] = useState(null);
  const [formValues, setFormValues] = useState(null); // Separate state for form values
  const [error, setError] = useState(null); // State to manage errors
  const params = useParams();
  const navigate = useNavigate();
  const [isSlideoverOpen, setIsSlideoverOpen] = useState(false); // State to control Slideover
  const [selectedSlideover, setSelectedSlideover] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!user) return;

    async function fetchCampaign() {
      try {
        if (params.id) {
          const docRef = doc(db, "campaign", params.id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const campaignData = docSnap.data();
            // Check if the logged-in user is the owner of the campaign
            if (campaignData.user !== user.uid) {
              setError("You do not have permission to edit this campaign."); // Set error message
              return;
            }
            const campaignWithId = {
              ...campaignData,
              id: docSnap.id,
            };
            setCampaign(campaignWithId);
            setFormValues(campaignWithId); // Initialize formValues with campaign data
          } else {
            setError("Campaign not found.");
            return;
          }
        }
      } catch (error) {
        setError("Error fetching campaign.");
        return;
      }
    }

    fetchCampaign();
  }, [params.id, user, navigate]);

  useEffect(() => {
    if (!params.id) return;

    const docRef = doc(db, "campaign", params.id);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const campaignData = docSnap.data();
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          ...campaignData,
        }));
      }
    });

    return () => unsubscribe();
  }, [params.id]);

  const deleteCampaignModal = () => {
    setShowAlert(true);
  };

  const handleAlertConfirm = (confirmed) => {
    setShowAlert(false); // Hide the alert after confirmation or cancellation
    if (confirmed) {
      deleteCampaign();
    }
  };

  const deleteCampaign = async () => {
    const docRef = doc(db, "campaign", params.id);
    try {
      await deleteDoc(docRef); // Delete the document/campaign
      // Set a timeout before redirecting
      setTimeout(() => {
        navigate("/"); // Redirect user after a delay, adjust the path as needed
      }, 500); // Delay in milliseconds, adjust as per your requirement
    } catch (error) {
      console.error("Error deleting campaign:", error);
      setError("Error deleting campaign. Please try again later."); // Set error message
    }
  };

  // Function to update a field in the campaign document
  const updateCampaign = async (field, value) => {
    const docRef = doc(db, "campaign", params.id);
    try {
      await setDoc(docRef, { [field]: value }, { merge: true });
      // Update local state to reflect the change
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [field]: value,
      }));
    } catch (error) {
      console.error("Error updating campaign:", error);
      setError("Error updating campaign. Please try again later."); // Set error message
    }
  };

  // Function to toggle Slideover for embedding
  const toggleSlideoverEmbed = () => {
    setSelectedSlideover("embed"); // Set the selected component to "embed"
    setIsSlideoverOpen(!isSlideoverOpen); // Toggle the Slideover's visibility
  };

  // Function to toggle Slideover for changing the campaign name
  const toggleSlideoverCampaignName = () => {
    setSelectedSlideover("name"); // Set the selected component to "name"
    setIsSlideoverOpen(!isSlideoverOpen); // Toggle the Slideover's visibility
  };

  const toggleSlideoverStatus = () => {
    setSelectedSlideover("status"); // Set the selected component to "status"
    setIsSlideoverOpen(!isSlideoverOpen); // Toggle the Slideover's visibility
  };

  const handleUpdateCampaignName = (newName) => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      name: newName,
    }));
  };

  const handleUpdateCampaignStatus = (status) => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      status: status,
    }));
  };

  const handleShareToggle = () => {
    const newStatus = !campaign.communityShared;
    updateCampaign("communityShared", newStatus);
  };

  const campaignActions = campaign ? [
    { 
      name: 'Play', 
      link: `/play/${campaign.id}`, 
      subheader: `Test and experience your game`, 
      icon: PlayIcon, 
      iconForeground: 'text-teal-700', 
      iconBackground: 'bg-teal-50',
      topRightIcon: ChevronRightIcon // You can change this to any other icon
    },
    { 
      name: 'Editor', 
      link: `/editor/${campaign.id}`, 
      subheader: `Customize your game`, 
      icon: PencilSquareIcon, 
      iconForeground: 'text-blue-700', 
      iconBackground: 'bg-blue-50',
      topRightIcon: ChevronRightIcon // You can change this to any other icon
    },
    { 
      name: 'Embed', 
      action: toggleSlideoverEmbed, 
      subheader: 'Integrate your game across websites, blogs, and social media', 
      icon: CodeBracketSquareIcon, 
      iconForeground: 'text-purple-700', 
      iconBackground: 'bg-purple-50',
      topRightIcon: ArrowLeftIcon // You can change this to any other icon
    }
  ] : [];

  const openEditor = () => {
    navigate(`/editor/${campaign.id}`);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AlertError text={error}/>
  }

  if (!formValues) {
    return <Loading />;
  }

  const actions = [
    { name: "Change status", action: toggleSlideoverStatus },
    { name: "Change name", action: toggleSlideoverCampaignName },
    { name: campaign.communityShared ? 'Unshare from Playground' : 'Share to Playground', action: handleShareToggle },
    { name: 'Delete', action: deleteCampaignModal }
  ];

  return (
    <>
      <div className="">
        <CampaignBanner title={campaign.name} name={campaign.options.title} status={campaign.status} actions={actions} onStatusClick={toggleSlideoverStatus}/>
        <CampaignProgress onGetEmbedLink={toggleSlideoverEmbed} onSetLive={toggleSlideoverStatus} campaignId={campaign.id} step={campaign.step}/>
        <Stats campaignIds={[campaign.id]} campaign={campaign} />
        <Actions actions={campaignActions} />
        <Slideover isOpen={isSlideoverOpen} onClose={() => setIsSlideoverOpen(false)} title={selectedSlideover === "embed" ? "Embed Campaign" : "Change Campaign Name"}>
          {selectedSlideover === "embed" && <CampaignEmbed campaignId={campaign.id} />}
          {selectedSlideover === "name" && <UpdateCampaignName campaignId={campaign.id} onClose={toggleSlideoverCampaignName} onUpdateCampaignName={handleUpdateCampaignName} />}
          {selectedSlideover === "status" && <UpdateCampaignStatus campaignId={campaign.id} onClose={toggleSlideoverStatus} currentStatus={campaign.status} onUpdateCampaignStatus={handleUpdateCampaignStatus} />}
        </Slideover>
        <AlertComponent
          title="Confirm delete"
          message="Are you sure you want to delete this campaign?"
          isOpen={showAlert}
          onConfirm={() => handleAlertConfirm(true)}
          onCancel={() => handleAlertConfirm(false)}
        />
      </div>
    </>
  );
}