import { useEffect, useState } from 'react';
import { collection, query, where, getDocs, FieldPath } from 'firebase/firestore';
import { db } from '../firebase';

export default function useFetchCampaigns(queryString = []) {
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    async function fetchCampaigns() {
      try {
        const q = query(
          collection(db, 'campaign'),
          where('communityShared', '==', true),
          ...queryString // Ensure queryString is an array of valid Firestore conditions
        );
  
        const querySnapshot = await getDocs(q);
        const records = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCampaigns(records);
      } catch (error) {
        console.error('Error fetching campaigns: ', error);
      } finally {
        setIsLoading(false);
      }
    }
  
    fetchCampaigns();
  }, [queryString]); // Ensure this array contains stable and correct dependencies

  return { campaigns, isLoading };
}