import React, { useState, useEffect } from 'react';
import { useUser } from '../userContext'; // Adjust the import path based on where you define the UserContext
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path to your firebase config
import HeadlineProfile from './HeadlineSetupProfile';

export default function Example() {
  const { user, currentPlan, isFreeUser, firstName: initialFirstName } = useUser();
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [options, setOptions] = useState({
    headline: '',
    subheadline: '',
    headline_url: '',
    headlinePhoto: '',
    displayHeadline: true // Set default to true
  });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    if (user) {
      async function fetchUserProfile() {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setFirstName(data.firstName);
          setOptions({
            headline: data.headline || 'Your headline',
            subheadline: data.subheadline || 'Visit website',
            headline_url: data.headline_url || 'www.smilesflow.com',
            headlinePhoto: data.headlinePhoto || '',
            displayHeadline: true
          });
        }
      }
      fetchUserProfile();
    }
  }, [user]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    if (saveSuccess) {
      const timer = setTimeout(() => {
        setSaveSuccess(false);
        window.location.reload(); // Reload the page after the success message disappears
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [saveSuccess]);

  const handleManageAccount = () => {
    if (user && user.email) {
      const portalUrl = `https://billing.stripe.com/p/login/8wM9C23PAffyeGc5kk?prefilled_email=${encodeURIComponent(user.email)}`;
      window.location.href = portalUrl;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOptions({
      ...options,
      [name]: value
    });
    setUnsavedChanges(true);
  };

  const onOptionsUpdate = (updatedOptions) => {
    setOptions(updatedOptions);
    setUnsavedChanges(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { 
        firstName,
        headline: options.headline,
        subheadline: options.subheadline,
        headline_url: options.headline_url,
        headlinePhoto: options.headlinePhoto
      });
      setUnsavedChanges(false);
      setSaveSuccess(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="space-y-12 sm:space-y-16 px-6 py-5">
          <div>
            <div className="flex items-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-700">Profile</h2>
              {unsavedChanges && (
                <span className="ml-2 inline-flex items-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                  Unsaved changes
                </span>
              )}
            </div>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Change your display name, customise your headline or manage your plan.
            </p>

            <div className="mt-5 space-y-8 mb-5">
              <div className="sm:col-span-4">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Display name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full max-w-2xl ">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      maxLength="20"
                      autoComplete="given-name"

                      className="block flex-1 border-0 bg-transparent ml-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-md sm:text-sm sm:leading-6"
                      value={firstName}
                      required
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setUnsavedChanges(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-800">Headline</h3>
              <p className="text-sm leading-6 text-gray-600">
                Your headline is optionally displayed on your campaigns.
              </p>
              <HeadlineProfile
                options={options}
                params={user?.uid}
                handleChange={handleChange}
                onOptionsUpdate={onOptionsUpdate}
              />
            </div>

            <div className="flex items-center justify-start">
              <button
                type="submit"
                disabled={!unsavedChanges}
                className={`inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full sm:w-auto ${
                  unsavedChanges ? 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600' : 'bg-gray-400 cursor-not-allowed'
                }`}
              >
                Save
              </button>
            </div>
            {saveSuccess && (
              <div className="mt-2 text-green-500 text-xs font-semibold">
                Profile updated
              </div>
            )}
          </div>
        </div>
      </form>

      <div className="px-5 py-5 mt-5 sm:p-6 border-t border-gray-900/10 mb-10">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Manage subscription</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Current plan: <span className='capitalize'>{currentPlan}</span></p>
        </div>
        <div className="mt-5 w-full text-center sm:text-left">
          <button
            type="button"
            onClick={handleManageAccount}
            className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full sm:w-auto"
          >
            Manage
          </button>
        </div>
      </div>
    </>
  );
}