import React, { useRef, useState } from 'react';
import { CheckIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../userContext';
import CheckoutButton from '../stripe/createCheckoutSession';
import { gameTypes } from '../data/GameTypes'; // Import the gameTypes array


export default function PlanModal() {
  const navigate = useNavigate();
  const { user, currentPlan, isFreeUser, firstName } = useUser();
  const checkoutRef = useRef();
  const [loadingButton, setLoadingButton] = useState(false);

  const tiers = [
    {
      name: 'Premium',
      id: 'premium',
      href: '',
      priceMonthly: '£29.99',
      text: 'Select Plan',
      description: 'Ideal for individuals and smaller organizations seeking impactful results.”',
      tierSelectType: 'triggerCheckout',
      priceId: process.env.REACT_APP_STRIPE_PRICE_ID_PREMIUM,
      features: [
        'Unlimited campaigns',
        'AI game generation',
        'Access to all game types',
        'Branding customisation',
        '1 user account'
      ],
    },
    {
      name: 'Agency/Team',
      id: 'enterprise',
      href: '#',
      priceMonthly: 'Custom',
      text: 'Contact Sales',
      description: 'Perfect for agencies or larger organizations, this plan gives you the tools to scale.',
      tierSelectType: 'email',
      priceId: '',
      features: [
'All Premium features',
        'Dedicated support',
        'Multiple user accounts',
        'Advanced branding customisation',
        'Game type proposals'
      ],
    },
  ];

  if (!user) {
    alert('You need to be logged in to subscribe.');
    return null;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleSelectTier = (tier) => {
    if (tier.tierSelectType === 'triggerCheckout') {
      setLoadingButton(true);
      if (checkoutRef.current) {
        checkoutRef.current.createCheckoutSession().finally(() => {});
      }
    } else if (tier.tierSelectType === 'email') {
      const subject = encodeURIComponent('SmilesFlow Enterprise Enquiry');
      const body = encodeURIComponent("Hi SmilesFlow,\n\nI am interested in the SmilesFlow enterprise plan. Here's a bit about us...");
      window.location.href = `mailto:sales@smilesflow.com?subject=${subject}&body=${body}`;
    } else if (tier.tierSelectType === 'basic') {
      window.location.href = '/';
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 overflow-x-hidden">
      <div className="relative w-full px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8 ">
        <button
  onClick={() => navigate(-1)}
          className="absolute top-4 right-4 rounded-full bg-gray-800 p-2 text-gray-400 hover:text-gray-200 focus:outline-none"
        >
          <XCircleIcon className="h-7 w-7" aria-hidden="true" />
        </button>
        <div className="mx-auto max-w-4xl">
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            The Perfect Plan for Everyone
          </p>
        </div>
        <div className="relative mt-6">
        <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
        Choose the plan that fits your needs and watch your engagement soar.
</p>
          <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
            <ellipse cx={604} cy={512} fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" rx={604} ry={512} />
            <defs>
              <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="w-full mx-auto max-w-7xl lg:px-8">
            <div className="mx-auto grid gap-8 px-6 sm:grid-cols-1 lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3 id={tier.id} className="text-base font-semibold leading-7 text-indigo-600">
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.priceMonthly}</span>
                      <span className="text-base font-semibold leading-7 text-gray-600">/month</span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">{tier.description}</p>
                    <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {tier.text && (tier.tierSelectType !== 'triggerCheckout' || tier.priceId) && (
                    <button
                      onClick={() => handleSelectTier(tier)}
                      aria-describedby={tier.id}
                      className={classNames(
                        tier.mostPopular && currentPlan !== tier.id
                          ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                          : 'text-indigo-600 ring-1 ring-inset ring-indigo-200',
                        currentPlan === tier.id ? 'bg-gray-400 text-white' : 'hover:ring-indigo-300',
                        currentPlan === tier.id ? 'border-none' : '',
                        'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                      )}
                      disabled={currentPlan === tier.id || (loadingButton && tier.tierSelectType === 'triggerCheckout')}
                    >
                      {currentPlan === tier.id ? 'Current Plan' : (loadingButton && tier.tierSelectType === 'triggerCheckout' ? 'Processing...' : tier.text)}
                    </button>
                  )}
                  {tier.id === 'premium' && (
                    <CheckoutButton ref={checkoutRef} uid={user.uid} priceId={tier.priceId} />
                  )}
                </div>
              ))}
              {isFreeUser && (
                <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                  <div className="lg:min-w-0 lg:flex-1">
                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Free</h3>
                    <p className="mt-1 text-base leading-7 text-gray-600">
                      Our free plan provides all the essential tools to get you started. Create unlimited campaigns with access to a select number of game types and basic branding customization options.
                    </p>
                  </div>
                  <button
     onClick={() => navigate(-1)}
                    className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Continue for Free <span aria-hidden="true">&rarr;</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}