import React, { useState } from 'react';
import { RadioGroup, Transition } from '@headlessui/react';
import { SparklesIcon, LockClosedIcon } from '@heroicons/react/20/solid'; // Import LockClosedIcon

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Step2({ useAI, setUseAI, isFreeUser, gameDescription, aiOutline, setGameDescription, campaignName, setCampaignName, onSubmit }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { id: 'ai', name: 'Generate with AI', description: 'Automatically generate an engaging and interactive game based on a brief outline. You can still edit after creation.', role: 'premium', disabled: isFreeUser },
    { id: 'manual', name: 'Create manually', description: 'Manually design and customize your game', role: 'free', disabled: false },
  ];

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setUseAI(value === 'ai');
  };

  return (
    <div>
      <RadioGroup value={selectedOption} onChange={handleOptionChange}>
        <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
          How would you like to create your game?
        </RadioGroup.Label>
        <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-1 sm:gap-x-4">
          {options.map((option) => (
            <RadioGroup.Option
              key={option.id}
              value={option.id}
              className={({ active }) =>
                classNames(
                  active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                  option.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                  'relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                )
              }
              disabled={option.disabled}
            >
              {({ checked, active }) => (
                <>
                  <div className="flex items-start">
                    <div className="flex items-center w-full">
                      <div className="flex flex-col w-full">
                        <div className="flex items-center justify-between w-full">
                          <RadioGroup.Label as="span" className="text-sm font-medium text-gray-900 flex items-center">
                            {option.id === 'ai' && (
                              <SparklesIcon className="h-4 w-4 text-indigo-500 mr-2" aria-hidden="true" />
                            )}
                            {option.name}
                          </RadioGroup.Label>
                          {option.id === 'ai' && isFreeUser && (
                            <span
                              className={classNames(
                                'bg-yellow-400 text-indigo-800 text-xs font-semibold px-2 py-0.5 rounded inline-flex items-center capitalize',
                                'ml-2'
                              )}
                            >
                              Premium
                              <LockClosedIcon className="ml-1 h-3 w-3 text-gray-500" aria-hidden="true" />
                            </span>
                          )}
                        </div>
                        <RadioGroup.Description as="span" className="mt-1 text-sm text-gray-500">
                          {option.description}
                        </RadioGroup.Description>
                      </div>
                    </div>
                  </div>
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-600' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {selectedOption && (
        <div className="mt-6">
          {useAI ? (
            <Transition
              show={true}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900 mt-10">Outline your game</h3>
                <p className="text-sm text-gray-500">Provide a brief outline of your game's topic to ensure we produce a game that perfectly fits your vision.</p>
                <form className="mt-3" id="gameDescriptionForm" onSubmit={onSubmit}>
                  <div className="w-full">
                    <label htmlFor="gameDescription" className="sr-only">
                      Description
                    </label>
                    <textarea
                      name="gameDescription"
                      id="gameDescription"
                      maxLength={500}
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder={aiOutline}
                      value={gameDescription}
                      onChange={(e) => setGameDescription(e.target.value)}
                      autoComplete="off" // or use a more specific autocomplete value

                    />
                  </div>
                </form>
              </div>
            </Transition>
          ) : (
            <Transition
              show={true}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900 mt-10">Name your campaign</h3>
                <p className="text-sm text-gray-500">You can change this later</p>

                <form className="mt-3" id="campaignNameForm" onSubmit={onSubmit}>
                  <div className="w-full">
                    <label htmlFor="campaignName" className="sr-only">
                      Name
                    </label>
                    <input
                      type="text"
                      name="campaignName"
                      id="campaignName"
                      maxLength={50}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Campaign Name"
                      value={campaignName}
                      onChange={(e) => setCampaignName(e.target.value)}
                      autoComplete="off" // or use a more specific autocomplete value
                    />
                  </div>
                </form>
              </div>
            </Transition>
          )}
        </div>
      )}
    </div>
  );
}