import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from '../firebase'; // Adjust the import path based on your file structure
import { useUser } from '../userContext';
import CardText from './CardText';
import List from './List';
import CampaignEmptyState from './NoLiveCampaign';
import Slideover from "./Slideover";
import NewCampaign from "./NewCampaign";
import StandardBanner from "./StandardBanner";
import Actions from './actions';
import DefaultLoading from "../common/DefaultLoading";
import StarterSteps from './StarterSteps';

import {
  PencilSquareIcon,
  ArrowTopRightOnSquareIcon,
  MinusCircleIcon,
  Bars4Icon,
  RocketLaunchIcon
} from '@heroicons/react/24/outline';
import { PlayIcon } from "@heroicons/react/24/solid";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

function Dashboard() {
  const { user, isFreeUser, firstName, introStepNumber } = useUser();
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSlideoverOpen, setIsSlideoverOpen] = useState(false);

  useEffect(() => {
    async function fetchCampaigns() {
      try {
        const q = query(
          collection(db, "campaign"),
          where("user", "==", user.uid),
          where("status", "==", "live")
        );
        const querySnapshot = await getDocs(q);
        const records = [];

        for (const doc of querySnapshot.docs) {
          const campaignData = doc.data();
          const stats = await fetchCampaignStats(doc.id);
          records.push({ id: doc.id, ...campaignData, stats });
        }

        setCampaigns(records);
      } catch (error) {
        console.error("Error fetching data: ", error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    }

    if (user) {
      fetchCampaigns();
    }
  }, [user, navigate]);

  const fetchCampaignStats = async (campaignId) => {
    try {
      const gameSummaryRef = doc(db, "gameSummary", campaignId);
      const gameSummaryDoc = await getDoc(gameSummaryRef);
      if (!gameSummaryDoc.exists()) {
        return getDefaultStats();
      }
      const data = gameSummaryDoc.data();

      let totalPlayCount = data.playCount || 0;
      let totalShared = data.totalShared || 0;
      let totalCta = data.totalCta || 0;
      let totalGameTimeInSeconds = data.totalGameTime || 0;
      let validGameCount = data.averageGameTime && data.averageGameTime < 3600 ? 1 : 0;
      let totalValidGameTimeInSeconds = validGameCount ? data.averageGameTime : 0;

      const averageGameTimeInMinutes = validGameCount > 0 ? (totalValidGameTimeInSeconds / validGameCount) / 60 : 0;
      const avgMinutes = Math.floor(averageGameTimeInMinutes);
      const avgSeconds = Math.round((averageGameTimeInMinutes - avgMinutes) * 60);
      const formattedAverageGameTime = `${avgMinutes}m ${avgSeconds}s`;

      const totalGameTimeInMinutes = totalGameTimeInSeconds / 60;
      const totalHours = Math.floor(totalGameTimeInMinutes / 60);
      const totalDays = Math.floor(totalHours / 24);
      let formattedTotalGameTime;

      if (totalDays > 0) {
        formattedTotalGameTime = `${totalDays}d ${totalHours % 24}h`;
      } else if (totalHours > 0) {
        const remainingMinutes = Math.round(totalGameTimeInMinutes % 60);
        formattedTotalGameTime = `${totalHours}h ${remainingMinutes}m`;
      } else {
        formattedTotalGameTime = `${Math.round(totalGameTimeInMinutes)}m`;
      }

      return {
        totalPlayCount: totalPlayCount.toLocaleString(),
        totalShared: totalShared.toLocaleString(),
        totalCta: totalCta.toLocaleString(),
        averageGameTime: formattedAverageGameTime,
      };
    } catch (error) {
      console.error("Error fetching stats: ", error);
      return getDefaultStats();
    }
  };

  const getDefaultStats = () => ({
    totalPlayCount: "0",
    totalShared: "0",
    totalCta: "0",
    averageGameTime: "0m 0s"
  });

  const toggleSlideover = () => {
    setIsSlideoverOpen(!isSlideoverOpen);
  };

  const slideoverTitle = "New campaign";

  const quickActions = [
    { 
      name: 'Create new', 
      action: toggleSlideover, 
      subheader: 'Create a new campaign', 
      icon: PencilSquareIcon, 
      iconForeground: 'text-blue-700', 
      iconBackground: 'bg-blue-50',
      topRightIcon: ArrowLeftIcon
    },
    { 
        name: 'My campaigns', 
        action: () => navigate('/campaigns'), 
        subheader: 'Go to your campaigns', 
        icon: PlayIcon, 
        iconForeground: 'text-teal-700', 
        iconBackground: 'bg-teal-50',
        topRightIcon: Bars4Icon
      },
  ];

  if (isLoading) {
    return <DefaultLoading/>;
  }

  return (
    <>
      <div className="relative lg:flex">
        <div className="lg:pr-96 w-full">
        <header className="border-b border-gray/5 px-4 py-6 sm:px-6">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Dashboard</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-3">
              Welcome, {firstName}
            </p>
          </header>

          {introStepNumber < 6 ? <StarterSteps createCampaign={toggleSlideover} /> : <CardText />}

          <StandardBanner header={"Live Campaigns"} buttonText={"View all"} buttonLink={"/campaigns"} />
          
          {campaigns.length > 0 ? (
            <List records={campaigns} paddingTop="pt-2 border-b border-gray-100" />
          ) : (
            <CampaignEmptyState
              header="You have no live campaigns."
              icon=''
              action="View all"
              actionLink="/campaigns"
            />
          )}
          
          <div className="sm:hidden">
            <Actions actions={quickActions} singleColumn={true} />
          </div>
        </div>
      </div>

      <aside className="hidden lg:block bg-white lg:fixed lg:bottom-0 lg:right-0 lg:top-16 lg:w-96 lg:overflow-y-auto lg:border-l lg:border-gray-100">
        <header className="flex items-center justify-between border-b border-gray-100 px-4 py-4 sm:px-6 sm:py-6 lg:px-6">
          <h2 className="text-base font-semibold leading-7 text-gray-800">Quick actions</h2>    </header>

        <Actions actions={quickActions} singleColumn={true} />
      </aside>

      <Slideover isOpen={isSlideoverOpen} onClose={toggleSlideover} title={slideoverTitle}>
        <NewCampaign />
      </Slideover>
    </>
  );
  
}

export default Dashboard;