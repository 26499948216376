import React from 'react';
import FormInput from '../games/FormInput';
import FileUpload from '../games/FileUpload';
import HeadlineCard from './HeadlineCard';
import { useUser } from '../userContext'; // Adjust the import path based on where you define the UserContext


const HeadlineProfile = ({ options, params, handleChange, onOptionsUpdate }) => {
  const { user } = useUser();

  return (
    <>
      <div className="flex items-center justify-between">
        <div className='py-5 w-full max-w-2xl rounded-lg'>

        <HeadlineCard
        url={options.headline_url}
        photoUrl={options.headlinePhoto}
        headline={options.headline}
        subheadline={options.subheadline}
        options={options}
      />

          <FileUpload
            onSuccess={onOptionsUpdate}
            onDelete={onOptionsUpdate}
            field="headlinePhoto"
            params={params}
            id={user.uid}
            collection={"users"}
            options={options}
            displayText={"Avatar"}
            displayPreview={false}
            />
          <FormInput
            label="Headline"
            name="headline"
            onChange={handleChange}
            placeholder="Company name"
            options={options}
            maxLength={30}
            mandatory={true}
          />
          <FormInput
            label="Subheadline"
            name="subheadline"
            onChange={handleChange}
            placeholder="Subheadline"
            options={options}
            maxLength={40}
            mandatory={true}
          />
          <FormInput
            label="Headline URL"
            name="headline_url"
            onChange={handleChange}
            placeholder="Headline URL"
            options={options}
            mandatory={true}
          />
        </div>
      </div>

  
    </>
  );
};

export default HeadlineProfile;