import React, { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Transition } from '@headlessui/react';

// Define the sliding animation
const slideInFromAbove = `
@keyframes slideInFromAbove {
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in {
    animation: slideInFromAbove 0.5s ease-out;
}
`;

export default function ComponentLoading({ text, loadingTick, spinnerOverride: SpinnerOverride, color = "#374151", animate = false }) {
    const [animationKey, setAnimationKey] = useState(0); // State to control animation

    // Effect to update animation key whenever text changes
    useEffect(() => {
        if (text) {
            setAnimationKey(prevKey => prevKey + 1); // Update key to restart animation
        }
    }, [text]);

    return (
        <div className="flex flex-col justify-center items-center h-full">
            {/* Conditionally apply the animation class */}
            {text && (
                <p
                    key={animationKey} // Use animationKey to force re-render
                    className={`mb-5 font-semibold text-center max-w-4/5 px-5 ${animate ? 'slide-in' : ''}`}
                    style={{ color }}
                >
                    {text}
                </p>
            )}
            
            {/* Conditionally render the spinner only if SpinnerOverride is not null */}
            {!loadingTick && SpinnerOverride !== null && (
                SpinnerOverride ? 
                    <SpinnerOverride size={10} color={color} /> : 
                    <BeatLoader size={10} color={color} />
            )}
            
            <Transition
                show={loadingTick}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <FontAwesomeIcon icon={faCheck} size="2x" color={color} />
            </Transition>

            {/* Add the animation CSS */}
            <style>{slideInFromAbove}</style>
        </div>
    );
}