import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function CardText() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/games');
  };

  return (
    <div className="bg-gray-50 border-b border-gray-100 mb-3 px-4 sm:px-6 py-4">
      <div className="mt-5 mb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Exciting updates</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            We are continuously expanding our selection of game types. You can even request new types for us to create. Explore our game library for all the details you need.
          </p>
        </div>
        <div className="mt-3 text-sm leading-6">
          <button
            onClick={handleNavigate}
            className="font-semibold text-gray-700 hover:text-gray-800"
          >
            Visit the game library
            <span aria-hidden="true"> &rarr;</span>
          </button>
        </div>
      </div>
    </div>
  );
}