import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, doc, query, orderBy, limit, startAfter, getDocs, getDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import List from "./List";
import CampaignEmptyState from "./NoLiveCampaign";
import Loading from "../common/DefaultLoading";
import HeaderBanner from "./HeaderBanner";
import NewCampaign from "./NewCampaign";
import Slideover from "./Slideover";
import useAuth from './useAuth';
import { PlayIcon } from "@heroicons/react/24/solid";

const INITIAL_LIMIT = 25;
const LOAD_MORE_LIMIT = 50;

export default function FullWidthGrid() {
  const [campaigns, setCampaigns] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastVisible, setLastVisible] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const { user, loading } = useAuth();
  const [isSlideoverOpen, setIsSlideoverOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        if (!user) {
          return;
        }
        
        // Fetch user first name
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setFirstName(userDoc.data().firstName);
        }

        // Fetch initial campaigns
        const q = query(
          collection(db, 'campaign'), // Adjusted to match collection name
          where("user", "==", user.uid),
          orderBy("createdAt", "desc"),
          limit(INITIAL_LIMIT)
        );
        const querySnapshot = await getDocs(q);
        const fetchedCampaigns = [];
        querySnapshot.forEach((doc) => {
          fetchedCampaigns.push({ id: doc.id, ...doc.data() });
        });

        console.log('Fetched campaigns:', fetchedCampaigns); // Debugging: Check fetched campaigns

        setCampaigns(fetchedCampaigns);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(fetchedCampaigns.length === INITIAL_LIMIT);
      } catch (error) {
        console.error("Error fetching data: ", error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [user, navigate]);

  const handleLoadMore = async () => {
    if (lastVisible) {
      setIsLoading(true);
      try {
        // Fetch more campaigns
        const q = query(
          collection(db, 'campaign'), // Adjusted to match collection name
          where("user", "==", user.uid),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(LOAD_MORE_LIMIT)
        );
        const querySnapshot = await getDocs(q);
        const moreCampaigns = [];
        querySnapshot.forEach((doc) => {
          moreCampaigns.push({ id: doc.id, ...doc.data() });
        });


        setCampaigns((prevCampaigns) => [...prevCampaigns, ...moreCampaigns]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(moreCampaigns.length === LOAD_MORE_LIMIT);
      } catch (error) {
        console.error("Error loading more campaigns: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleSlideover = () => {
    setIsSlideoverOpen(!isSlideoverOpen);
  };

  if (loading || isLoading) {
    return <Loading />;
  }

  const slideoverTitle = "New campaign";

  return (
    <>
      <div className="py-7">
        <HeaderBanner
          title={"Campaigns"}
          subheader={`Hello ${firstName || 'User'}`}
          actions={[
            {
              name: "New",
              action: toggleSlideover,
            }
          ]}
        />
        {campaigns.length > 0 ? (
          <div>
            <List records={campaigns} />
            {hasMore && (
   <button
   onClick={handleLoadMore}
   className="px-3 py-5 text-sm w-full font-semibold text-gray-600 bg-gray-50  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mx-auto block "
 >
   {isLoading ? "Loading..." : "Load more"}
 </button>
            )}
          </div>
        ) : (
          <div className="pt-5">
            <CampaignEmptyState
              header="You do not have any campaigns."
              icon={PlayIcon}
              action=" Get started"
              actionFunction={toggleSlideover}
            />
          </div>
        )}
        <Slideover isOpen={isSlideoverOpen} onClose={toggleSlideover} title={slideoverTitle}>
          <NewCampaign />
        </Slideover>
      </div>
    </>
  );
}