import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [introStepNumber, setIntroStepNumber] = useState(2);  // Default to step 1

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        try {
          await currentUser.getIdToken(true);
          const decodedToken = await currentUser.getIdTokenResult();
          const plan = decodedToken.claims.stripeRole || 'free';
          setCurrentPlan(plan);

          // Fetch user first name and intro step number
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFirstName(userData.firstName);
            setIntroStepNumber(userData.IntroStepNumber || 2);  // Default to step 1 if not set
          }
        } catch (error) {
          console.error("Error checking plan status or fetching user data:", error);
        }
      } else {
        setUser(null);
        setFirstName(null);
        setIntroStepNumber(2);  // Reset to default step 1 if not logged in
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const isFreeUser = currentPlan === 'free';

  const updateIntroStepNumber = async (newStepNumber) => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      try {
        await setDoc(userRef, { IntroStepNumber: newStepNumber }, { merge: true });
        setIntroStepNumber(newStepNumber);
      } catch (error) {
        console.error("Error updating IntroStepNumber:", error);
      }
    }
  };

  return (
    <UserContext.Provider value={{ user, loading, currentPlan, isFreeUser, firstName, introStepNumber, updateIntroStepNumber }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);