import React from 'react';
import { useNavigate } from 'react-router-dom';

function StandardBanner({ header, subheader, buttonText, buttonLink, border }) {
  const navigate = useNavigate();

  return (
    <div className={`bg-white ${border ? 'border-b border-gray-200 pb-4' : ''}`}>
      <div className="flex items-center justify-between px-4 sm:px-6 py-4">
        <div>
          <h1 className="text-base font-semibold leading-7 text-indigo-700 overflow-hidden">
            {header}
          </h1>
          {subheader && (
            <p className="text-sm text-gray-500 mt-1">
              {subheader}
            </p>
          )}
        </div>
        {buttonText && buttonLink && (
          <button
            onClick={() => navigate(buttonLink)}
            className="inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-600 hover:text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}

export default StandardBanner;