import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import { PlayIcon, PresentationChartLineIcon, ChartPieIcon, ClockIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';

export default function Stats({ campaignIds, campaign }) {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [infoVisible, setInfoVisible] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let totalPlayCount = 0;
        let totalShared = 0;
        let totalCta = 0;
        let totalGameTimeInSeconds = 0;
        let totalValidGameTimeInSeconds = 0;
        let validGameCount = 0;

        for (const gameId of campaignIds) { 
          const gameSummaryRef = doc(db, "gameSummary", gameId);
          const gameSummaryDoc = await getDoc(gameSummaryRef);

          if (gameSummaryDoc.exists()) {
            const data = gameSummaryDoc.data();
            totalPlayCount += data.playCount || 0;
            totalShared += data.totalShared || 0;
            totalCta += data.totalCta || 0;
            totalGameTimeInSeconds += data.totalGameTime || 0;

            if (data.averageGameTime && data.averageGameTime < 3600) {
              totalValidGameTimeInSeconds += data.averageGameTime;
              validGameCount++;
            }
          }
        }

        const averageGameTimeInMinutes = validGameCount > 0 ? (totalValidGameTimeInSeconds / validGameCount) / 60 : 0;

        // Format average game time as mm:ss
        const avgMinutes = Math.floor(averageGameTimeInMinutes);
        const avgSeconds = Math.round((averageGameTimeInMinutes - avgMinutes) * 60);
        const formattedAverageGameTime = `${avgMinutes}m ${avgSeconds}s`;

        // Format total game time
        const totalGameTimeInMinutes = totalGameTimeInSeconds / 60;
        const totalHours = Math.floor(totalGameTimeInMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);
        let formattedTotalGameTime;

        if (totalDays > 0) {
          // Format in days
          formattedTotalGameTime = `${totalDays}d ${totalHours % 24}h`;
        } else if (totalHours > 0) {
          // Format in hours and minutes
          const remainingMinutes = Math.round(totalGameTimeInMinutes % 60);
          formattedTotalGameTime = `${totalHours}h ${remainingMinutes}m`;
        } else {
          // Format in minutes
          formattedTotalGameTime = `${Math.round(totalGameTimeInMinutes)}m`;
        }

        setStats([
          { name: 'Total Play Count', value: totalPlayCount.toLocaleString() },
          { name: 'Total Shares', value: totalShared.toLocaleString() },
          { name: 'Call to Action Clicks', value: totalCta.toLocaleString() },
          { name: 'Average Game Time', value: formattedAverageGameTime }
        ]);

      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (campaignIds && campaignIds.length > 0) {
      fetchData();
    } else {
      setIsLoading(false); // No campaign IDs, nothing to load
    }
  }, [campaignIds]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  if (isLoading) {
    return null; // Display loading indicator
  }

  return (
    <div>
      {infoVisible && (
        <div className={classNames(
          campaign.status === 'live' ? 'bg-green-50' : 'bg-blue-50',
          'p-2 pt-3 px-4 sm:px-6 lg:px-8'
        )}>
          <div className="flex">
            <div className="flex-shrink-0">
              {campaign.status === 'live' ? (
                <CheckCircleIcon className="h-4 w-4 text-green-400" aria-hidden="true" />
              ) : (
                <InformationCircleIcon className="h-4 w-4 text-blue-400" aria-hidden="true" />
              )}
            </div>
            <div className="ml-3">
              <p className={classNames(
                campaign.status === 'live' ? 'text-green-600' : 'text-blue-600',
                'text-xs font-medium'
              )}>
                {campaign.status === 'live' ? 'Stats are being collected' : 'Stats are not collected when the game is not live'}
              </p>
            </div>
            <div className="ml-auto pl-3 pr-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className={classNames(
                    campaign.status === 'live' ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50' : 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50',
                    'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2'
                  )}
                  onClick={() => setInfoVisible(false)}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 sm:grid-cols-4 bg-white border-t border-gray-200">
        {stats.map((stat, statIdx) => (
          <div
            key={stat.name}
            className={classNames(
              'border-gray-200 px-4 py-6 sm:px-6 lg:px-8',
              'border-b',
              statIdx % 2 === 1 ? 'border-l' : '',
              statIdx === 2 ? 'sm:border-l sm:border-b' : '', // Ensure statIdx 2 has bottom border on large screens
              statIdx === 3 ? 'sm:border-b sm:border-l' : '', // Ensure statIdx 3 has bottom and left borders on large screens
              statIdx === 0 || statIdx === 1 ? 'sm:border-b' : '' // Ensure statIdx 0 and 1 have bottom borders on large screens
            )}
          >
            <p className="text-sm font-medium leading-6 text-gray-500">{stat.name}</p>
            <p className="mt-2 flex items-baseline gap-x-2">
              <span className="text-xl sm:text-3xl font-semibold tracking-tight text-gray-900">{stat.value}</span>
              {stat.unit ? <span className="text-sm text-gray-500">{stat.unit}</span> : null}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}