import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from "../common/ComponentLoading";
import { useUser } from '../userContext';
import { gameTypes } from '../data/GameTypes'; // Import the gameTypes array
import Step1 from './NewCampaignStep1';
import Step2 from './NewCampaignStep2'; // Updated Step2 component
import { SquareLoader } from "react-spinners";

export default function NewCampaign() {
  const [selectedGameType, setSelectedGameType] = useState(null); // Use null initially
  const [useAI, setUseAI] = useState(false);
  const [gameDescription, setGameDescription] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const { introStepNumber, updateIntroStepNumber, user, isFreeUser } = useUser(); // Use the useUser hook
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Creating your campaign...");
  const [loadingTick, setLoadingTick] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const preSelectedGameType = params.get('gameType');

  useEffect(() => {
    if (preSelectedGameType) {
      const gameType = gameTypes.find(type => type.id === preSelectedGameType);
      if (gameType && (!isFreeUser || gameType.role === 'free')) {
        setSelectedGameType(gameType);
      } else {
        setSelectedGameType(null);
      }
    }
  }, [preSelectedGameType, isFreeUser]);

  async function onSubmit(event) {
    event.preventDefault();
  
    if (!selectedGameType || (!useAI && !campaignName) || (useAI && !gameDescription)) {
        alert("Please fill in all fields.");
        return;
    }
  
    setIsLoading(true);
    setLoadingText(useAI ? "Building your campaign" : "Creating your campaign");
  
    const loadingTexts = [
        "Building your campaign",
        "Fetching content",
        "Wrapping things up",
        "Final touches"
    ];
  
    let textIndex = 0;
  
    const intervalId = setInterval(() => {
        textIndex = (textIndex + 1) % loadingTexts.length;
        setLoadingText(loadingTexts[textIndex]);
    }, 8000);
  
  
    try {
        let options = selectedGameType.options;
        
        if (useAI) {
            
            
            const response = await fetch(process.env.REACT_APP_GAME_GENERATION_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ description: gameDescription, gameTypeId: selectedGameType.id }),
            });

  
            if (!response.ok) {
                const responseText = await response.text();
                console.error('AI API error response text:', responseText);
                throw new Error('Failed to generate game');
            }
  
            var dataResponse = await response.json()
            const parsedData = dataResponse.message; // Assuming backend now sends parsed data
  
            if (!parsedData || !parsedData.options) {
                console.error('No valid options in the response:', parsedData);
                throw new Error('Invalid response from OpenAI');
            }
  
            // Directly use parsedData now without extra parsing
            options = {
                ...options,
                title: parsedData.options.title || options.title,
                intro: parsedData.options.intro || options.intro,
                data: parsedData.options.data || options.data,
            };
  
            if (parsedData.options.dummyData || options.dummyData) {
                options.dummyData = parsedData.options.dummyData || options.dummyData;
            }
  
            if (parsedData.options.start_view || options.start_view) {
                options.start_view = parsedData.options.start_view || options.start_view;
            }
  
            if (parsedData.options.mapType || options.mapType) {
                options.mapType = parsedData.options.mapType || options.mapType;
            }
        }
  
        const dataToInsert = {
            gameType: selectedGameType.id,
            gameTypeDisplayValue: selectedGameType.name,
            name: campaignName || "AI Generated " + selectedGameType.name,
            user: user.uid,
            status: 'draft',
            options,
            createdAt: new Date()  // Add createdAt field with current timestamp
        };
  
        const docRef = await addDoc(collection(db, 'campaign'), dataToInsert);
  
        setTimeout(() => {
            if (introStepNumber < 4) {
                updateIntroStepNumber(4);
            }
            setLoadingText("Campaign created");
            setLoadingTick(true);
            setTimeout(() => {
                navigate('/campaign/' + docRef.id);
            }, 2000);
        }, 2000);
  
    } catch (e) {
        console.error('Error creating campaign: ', e.message);
        console.error('Full error details:', e);
        alert('There was an error creating your campaign. Please try again.');
        setIsLoading(false);
    } finally {
        clearInterval(intervalId); // Clear the interval when done
    }
}

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    const form = document.getElementById(useAI ? "gameDescriptionForm" : "campaignNameForm");
    if (form) {
      form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };


  if (isLoading) {
    return <Loading text={loadingText} loadingTick={loadingTick} spinnerOverride={SquareLoader} animate={true}  />;
  }

  return (
    <div className='relative h-full flex flex-col'>
      <div className='flex-1 flex flex-col overflow-y-auto px-1 pb-20'>
        {currentStep === 1 && (
          <Step1
            selectedGameType={selectedGameType}
            setSelectedGameType={setSelectedGameType}
            isFreeUser={isFreeUser}
          />
        )}
        {currentStep === 2 && (
          <Step2
            useAI={useAI}
            setUseAI={setUseAI}
            isFreeUser={isFreeUser}
            gameDescription={gameDescription}
            aiOutline={selectedGameType?.aiOutline}
            setGameDescription={setGameDescription}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            onSubmit={onSubmit} // Pass the onSubmit function to Step2
          />
        )}
      </div>
      <div className="absolute bottom-0 left-0 w-full bg-white">
        <div className="flex justify-between px-1 pt-1">
          {currentStep < 2 && (
            <button
              type="button"
              onClick={handleNextStep}
              className="w-full flex items-center justify-center rounded-md px-3 py-3 text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-500 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              disabled={!selectedGameType && currentStep === 1}
            >
              Next
            </button>
          )}
          {currentStep === 2 && (
            <button
              type="button"
              onClick={handleSubmit}
              className={`w-full flex items-center justify-center rounded-md px-3 py-3 text-sm font-semibold text-white shadow-sm focus:ring-2 focus:ring-offset-2 ${
                (useAI && !gameDescription) || (!useAI && !campaignName)
                  ? 'bg-indigo-400 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-600'
              }`}
              disabled={(useAI && !gameDescription) || (!useAI && !campaignName)}
            >
              Create
            </button>
          )}
        </div>
      </div>
    </div>
  );
}