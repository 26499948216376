import React, { useState } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { db } from '../firebase';
import useAuth from './useAuth';
import { useNavigate } from 'react-router-dom';
import Loading from "../common/ComponentLoading";
import { useUser } from '../userContext'; // Import the useUser hook
import { updateCampaignStep } from '../common/Database';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const states = [
  { id: 'draft', title: 'Draft', description: 'A work in progress' },
  { id: 'ready', title: 'Ready', description: 'Ready to be embedded' },
  { id: 'live', title: 'Live', description: 'Currently embedded and active' },
  { id: 'complete', title: 'Complete', description: 'No longer embedded' }
];

export default function UpdateStatus({ campaignId, currentStatus, onClose, onUpdateCampaignStatus }) {
  const { user, loading } = useAuth();
  const { introStepNumber, updateIntroStepNumber } = useUser(); // Use the useUser hook
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [selectedState, setSelectedState] = useState(states.find(state => state.id === currentStatus)); // Add state for selected campaign status
  const [loadingText, setLoadingText] = useState("Updating your campaign...");
  const [loadingTick, setLoadingTick] = useState(false);

  async function onSubmit(event) {
    event.preventDefault();

    setIsLoading(true); // Start loading

    const docRef = doc(db, "campaign", campaignId);
    try {
      await setDoc(docRef, { status: selectedState.id }, { merge: true });

      // First timeout to set the loading text
      setTimeout(() => {

        if(selectedState.id==='live'){
          updateCampaignStep(campaignId, 5)
        }
           // Update step number to 5 if the selected state is 'live' and introStepNumber is less than 5
     if (selectedState.id === 'live' && introStepNumber < 5) {
        updateIntroStepNumber(5);
      } 
        setLoadingText("Updated");
        setLoadingTick(true);
        onUpdateCampaignStatus(selectedState.id); // This updates the parent component's state

        // Second timeout to execute the onClose function after an additional delay
        setTimeout(() => {
          onClose();
        }, 1000); // Adjust the delay for the onClose as needed
      }, 1000);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }

  if (isLoading) {
    return <Loading text={loadingText} loadingTick={loadingTick} />; // Show loading component while updating data
  }

  return (
    <div className="relative h-full">
      <form className="mt-3" id="updateStatus" onSubmit={onSubmit}>
        <RadioGroup value={selectedState} onChange={setSelectedState}>
          <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
            Select status
          </RadioGroup.Label>
          <p className="text-sm text-gray-500">Choose the current status of your campaign</p>

          <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-1 sm:gap-x-4">
            {states.map((state) => (
              <RadioGroup.Option
                key={state.id}
                value={state}
                className={({ active }) =>
                  classNames(
                    active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                          {state.title}
                        </RadioGroup.Label>
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-indigo-600' : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg'
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </form>

      {/* Positioning the submit button at the bottom of the screen */}
      <div className="absolute bottom-0 left-0 w-full pb-2">
        <p className="text-xs text-gray-500 mb-3">
          State only affects stat collection. Users can still access campaigns that are not live directly.
        </p>
        <button
          type="submit"
          form="updateStatus"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Update
        </button>
      </div>
    </div>
  );
}