import Logo from '../images/logoclear.png';
import BigLogo from '../images/logo.png';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import DisplayGames from './DisplayGames';
import SectionHeading from './SectionHeading';
import GamesList from './GamesList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { where } from 'firebase/firestore';
import Footer from './footer';

export default function Home() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const query = event.target.search.value.trim();
    
    if (query) {
      navigate(`/playground?q=${query}`);
    } else {
      navigate('/playground');
    }
    
    // Hide the keyboard on mobile devices
    event.target.search.blur();
  };

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-40"> {/* Ensure z-40 for header */}
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="/playground" className="-m-1.5 p-1.5">
              <span className="sr-only">SmilesFlow</span>
              {/* Small Logo for small screens */}
              <img alt="SmilesFlow" src={Logo} className="h-10 w-auto block lg:hidden" />
              {/* Big Logo for larger screens */}
              <img alt="SmilesFlow" src={BigLogo} className="h-8 w-auto hidden lg:block" />
            </a>
          </div>
          <div className="flex lg:flex-1 lg:justify-end">
            <a href="/" className="text-sm font-semibold rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Create a game <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
      </header>

      <div className="relative isolate px-6 pt-10 pb-10 lg:px-8 lg:pt-14 lg:pb-14">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-3xl pt-48 pb-10">
          <div className="text-center">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Playground by SmilesFlow
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Discover endless inspiration with games shared by the community, or simply unwind and have fun.
            </p>
            <div className="mt-8 flex items-center justify-center gap-x-6">
              <form onSubmit={handleSearchSubmit} className="relative w-full max-w-3xl">
                <label htmlFor="search" className="sr-only">Search</label>
                <div className="relative text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search"
                    name="search"
                    type="search"
                    placeholder="Search"
                    defaultValue={searchQuery || ''}
                    className="block w-full rounded-md border-0 bg-white py-2 pl-10 pr-3 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
      
      {/* This section is outside the colored background */}
      <div className="relative mx-auto max-w-7xl mb-20 px-4 sm:px-6 lg:px-8 z-50"> {/* Added z-50 */}
        {searchQuery ? (
          <>
            <SectionHeading title={"Search results"} />
            <GamesList queryString={[]} searchQuery={searchQuery} />
          </>
        ) : (
          <>
            <SectionHeading title={"Featured games"} />
            <div className='mt-5'>
              <DisplayGames queryString={[where('featured', '==', true)]} />
            </div>
            <SectionHeading title={"All"} showSort={false}/>
            <GamesList />
          </>
        )}
      </div>
      <Footer/>
    </div>
  )
}