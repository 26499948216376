import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { sendPasswordResetEmail } from "firebase/auth";

export default function PasswordResetModal({ show, onClose, auth }) {
    const [resetEmail, setResetEmail] = useState("");
    const [resetNotice, setResetNotice] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handlePasswordReset = async () => {
        if (!resetEmail) {
            setResetNotice("Please enter your email address to reset your password.");
            setIsSuccess(false);
            return;
        }

        try {
            await sendPasswordResetEmail(auth, resetEmail);
            setResetNotice("Password reset email sent. Please check your inbox, it may end in your junk folder.");
            setIsSuccess(true);
            setIsButtonDisabled(true);

            // Re-enable the button after 2 minutes
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 120000); // 2 minutes
        } catch (error) {
            let errorMessage = "Error sending password reset email. Please try again.";
            switch (error.code) {
                case 'auth/invalid-email':
                    errorMessage = "The provided email is not valid.";
                    break;
                case 'auth/user-not-found':
                    errorMessage = "No user found with this email.";
                    break;
                default:
                    console.error("Error sending password reset email: ", error);
            }
            setResetNotice(errorMessage);
            setIsSuccess(false);
            setIsButtonDisabled(false); // Re-enable the button in case of error
        }
    };

    return (
        <Transition appear show={show} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="transition-all duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="transition-all duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div className="flex justify-between items-center">
                                    <Dialog.Title as="h3" className="text-md font-medium leading-6 text-gray-900">
                                         Password reset
                                    </Dialog.Title>
                                    <button
                                        type="button"
                                        className="text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={onClose}
                                    >
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <p className="mt-2 mb-5 text-sm text-gray-500">
                                    Please note: it may take up to 5 minutes for the email to arrive.
                                </p>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={resetEmail}
                                        onChange={(e) => setResetEmail(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  
                                </div>
                                <div className="mt-5 sm:mt-6">
                                {resetNotice && 
                                        <div className={`text-sm mb-5  ${isSuccess ? 'text-green-600' : 'text-red-600'}`}>
                                            <div className="flex items-center">
                                                {isSuccess ? <CheckCircleIcon className="h-5 w-5 text-green-600 mr-2" /> : <ExclamationCircleIcon className="h-5 w-5 text-red-600 mr-2" />}
                                                <span>{resetNotice}</span>
                                            </div>
                                        </div>
                                    }
                                    {!isButtonDisabled && (
                                        <button
                                            type="button"
                                            onClick={handlePasswordReset}
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            disabled={isButtonDisabled}
                                        >
                                            Send reset email
                                        </button>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}