import { Transition } from '@headlessui/react';
import React, { useState, useEffect, useRef } from 'react';
import Play from "./Play";
import { useParams, useNavigate } from "react-router";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from '../firebase';
import useAuth from '../dashboard/useAuth';
import Loading from "../common/DefaultLoading";
import AlertComponent from '../dashboard/DialogConfirm';
import AlertError from '../dashboard/PermissionDenied';
import { updateCampaignStep } from '../common/Database';

export default function Editor() {
  const params = useParams();
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(true); // Initial state is true to indicate loading
  const [gameData, setGameData] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [FormComponent, setFormComponent] = useState(null);
  const [error, setError] = useState(null); // State to manage errors
  const [errors, setErrors] = useState({}); // State to track validation errors
  const formRef = useRef(null); // Reference to the form element

  useEffect(() => {
    console.log('Loading game component...');
    const loadGameComponent = async () => {
      const docRef = doc(db, "campaign", params.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const foundDoc = docSnap.data();
        const gameType = foundDoc.gameType;

        try {
          const importedComponent = React.lazy(() =>
            import(`../games/${gameType}/Form`)
          );
          setFormComponent(importedComponent);
          console.log('Game component loaded:', gameType);
        } catch (error) {
          console.error('Error loading game component:', error);
          setError("Error loading campaign.");
          setIsLoading(false);
          return;
        }
      } else {
        console.error('Campaign not found');
        setError("Campaign not found");
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };

    loadGameComponent();
  }, [params.id]);

  useEffect(() => {
    if (!user) return;
    console.log('User detected, fetching campaign...');
    fetchCampaign();
  }, [params.id, user, navigate]);

  const fetchCampaign = async () => {
    setIsLoading(true);
    try {
      if (params.id) {
        const docRef = doc(db, "campaign", params.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const campaignData = docSnap.data();
          console.log('Campaign data fetched:', campaignData);
          // Check if the logged-in user is the owner of the campaign
          if (campaignData.user !== user.uid) {
            console.error('User does not have permission to edit this campaign');
            setError("You do not have permission to edit this campaign.");
            setIsLoading(false);
            return;
          }
          setGameData(campaignData);
          setIsLoading(false);
        } else {
          console.error('Campaign not found');
          setError("Campaign not found.");
          setIsLoading(false);
          return;
        }
      }
    } catch (error) {
      console.error('Error fetching campaign:', error);
      setError("Campaign not found.");
      setIsLoading(false);
      return;
    }
  };

  const handleOptionsUpdate = (updatedOptions) => {
    setUnsavedChanges(true);
    setGameData(prevGameData => ({
      ...prevGameData,
      options: { ...prevGameData.options, ...updatedOptions }
    }));
  };

  const validateForm = () => {
    // Trigger form validation
    const form = formRef.current;
    if (!form.reportValidity()) {
      console.error('Validation failed, not saving');
      return false; // Return false if validation fails
    }
    return true; // Return true if validation passes
  };

  const saveOptionsToDB = async () => {
    if (!validateForm()) return;
    setUnsavedChanges(false);
    try {
      await updateCampaignStep(params.id, 3);
      const docRef = doc(db, "campaign", params.id);
      await setDoc(docRef, gameData, { merge: true });
      await fetchCampaign();
      console.log('Options saved successfully');
    } catch (error) {
      console.error('Error saving options to database:', error);
    }
  };

  const closeEditor = () => {
    if (unsavedChanges) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      navigate(`/campaign/${params.id}`);
    }
  };

  const handleAlertConfirm = (confirmed) => {
    setShowAlert(false);
    if (confirmed) {
      navigate(`/campaign/${params.id}`);
    }
  };

  if (loading || isLoading) return <Loading />;

  if (error) {
    return <AlertError text={error} />;
  }

  return (
    <>
      <div className={'h-full flex flex-col'}>
        <div className="flex flex-1 overflow-hidden">
          <div className="w-full flex flex-col md:flex-row">
            {/* Left column for the FormComponent */}
            <div className={`w-full md:w-2/5 overflow-y-auto ${unsavedChanges ? 'mb-20 sm:mb-0 xs:mb-20' : ''}`}>
              <div className="flex justify-between items-center bg-white py-2 px-2 w-full border-b border-gray-50">
                <div>
                  <button
                    className="flex items-center px-3 py-2 text-gray-600 font-semibold text-sm hover:text-gray-700"
                    onClick={closeEditor}
                  >
                     Exit
                  </button>
                </div>
                <div className='text-center'>
                  <div className='text-sm'>{gameData.name}</div>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    disabled={!unsavedChanges}
                    className={`flex items-center px-3 py-2 text-white text-sm rounded ${!unsavedChanges ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 cursor-pointer hover:bg-indigo-700'}`}
                    onClick={saveOptionsToDB}
                  >
                    Save
                  </button>
                </div>
              </div>

              {FormComponent && (
                <>
                  {Object.entries(errors).map(([field, errorMessage]) => (
                    <div key={field}>
                      <AlertError text={errorMessage} dashboardButton={false} />
                    </div>
                  ))}

                  <form ref={formRef} className="py-4">
                    <FormComponent options={gameData.options} game={gameData} onOptionsUpdate={handleOptionsUpdate} params={params} validateForm={validateForm} errors={errors} />
                  </form>
                </>
              )}
            </div>

            <div className="hidden md:block md:w-3/5 z-100 bg-gray-500">
              <Play />
            </div>
          </div>
        </div>
      </div>

      {/* Floating Save Button */}
      <Transition
        show={unsavedChanges}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
   <div className="fixed z-20 bottom-0 left-0 right-0 bg-white shadow-md py-4 px-4 flex justify-center md:hidden" style={{ boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}>
  <button
    disabled={!unsavedChanges}
    className={`flex items-center justify-center w-full px-4 py-3 text-white text-sm rounded ${!unsavedChanges ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 cursor-pointer hover:bg-indigo-700'}`}
    onClick={saveOptionsToDB}
  >
    Save changes
  </button>
</div>
      </Transition>

      <AlertComponent
        title="Unsaved changes"
        message="You have unsaved changes, are you sure you want to close the editor?"
        isOpen={showAlert}
        onConfirm={() => handleAlertConfirm(true)}
        onCancel={() => handleAlertConfirm(false)}
      />
    </>
  );
}