import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path based on your file structure
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import useAuth from './useAuth'; // Adjust the import path based on where you define the useAuth hook
import { statuses } from '../data/StatusColours';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SearchCampaigns() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const { user } = useAuth(); // Get the current user
  const navigate = useNavigate();
  const comboboxRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });

  const fetchAllCampaigns = () => {
    if (!user) return;

    const q = query(
      collection(db, 'campaign'),
      where('user', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({
          id: doc.id,
          name: data.name,
          title: data.options?.title,
          status: data.status,
        });
      });
      setAllCampaigns(results);
      console.log('Fetched all campaigns:', results);
    }, (error) => {
      console.error('Error fetching campaigns:', error);
    });

    return unsubscribe; // Return the unsubscribe function to stop listening when the component unmounts
  };

  useEffect(() => {
    const unsubscribe = fetchAllCampaigns();
    return () => unsubscribe && unsubscribe(); // Unsubscribe when the component unmounts
  }, [user]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value.length >= 1) {
      const filteredResults = allCampaigns.filter(
        (record) =>
          record.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          record.title?.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setSearchResults(filteredResults.slice(0, 4)); // Limit to 4 results
    } else {
      setSearchResults([]);
    }
  };

  const handleCampaignClick = (record) => {
    if (record && record.id) {
      navigate(`/campaign/${record.id}`);
      setSearchQuery(''); // Clear the search bar
      setSearchResults([]); // Reset the dropdown
      document.activeElement.blur(); // Remove focus from the input field
    }
  };

  // Close the search dropdown on click or touch away
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setSearchQuery(''); // Clear the search query
        setSearchResults([]); // Reset the search results
      }
    };

    const handleTouchStart = (event) => {
      const touch = event.touches[0];
      touchStartRef.current = { x: touch.clientX, y: touch.clientY };
    };

    const handleTouchEnd = (event) => {
      const touch = event.changedTouches[0];
      const deltaX = Math.abs(touch.clientX - touchStartRef.current.x);
      const deltaY = Math.abs(touch.clientY - touchStartRef.current.y);

      if (deltaX < 10 && deltaY < 10) {
        handleOutsideClick(event);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <div ref={comboboxRef} className="relative w-full">
      <Combobox as="div" onChange={handleCampaignClick} className="relative w-full">
        <div className="relative">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
          <ComboboxInput
            className="block w-full border-0 py-2 pl-8 pr-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <Transition
          show={searchQuery.length >= 1 && searchResults.length > 0}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions
            static
            className="absolute overflow-hidden z-10 mt-1 -ml-20 w-[calc(100%+145px)] sm:-ml-20 sm:w-[calc(100%+148px)] lg:-ml-10 lg:w-[calc(100%+220px)] max-h-60 overflow-y-auto bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style={{ overflowX: 'hidden' }}
          >
            {searchResults.map((record) => (
              <ComboboxOption
                key={record.id}
                value={record}
                className={({ active }) =>
                  classNames('cursor-default select-none px-4 py-2 pl-6 sm:pl-10', active && 'bg-gray-50')
                }
              >
                <div className="flex items-center">
                  <span className={classNames(
                    statuses[record.status],
                    'inline-block rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset capitalize'
                  )}>
                    {record.status}
                  </span>
                  <span className="font-medium ml-2 overflow-hidden text-ellipsis whitespace-nowrap">{record.name}</span>
                </div>
                <p className="mt-1 text-xs text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">{record.title}</p>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>

        <Transition
          show={searchQuery.length >= 1 && searchResults.length === 0}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <p className="absolute overflow-hidden z-10 py-5 pl-6 sm:pl-10 mt-1 -ml-20 w-[calc(100%+140px)] sm:-ml-20 sm:w-[calc(100%+148px)] lg:-ml-8 lg:w-[calc(100%+218px)] bg-white py-2 px-4 text-sm text-gray-500 shadow-lg ring-1 ring-black ring-opacity-5" style={{ overflowX: 'hidden' }}>
            No campaigns found.
          </p>
        </Transition>
      </Combobox>
    </div>
  );
}