import React, { useState, useEffect } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import useAuth from './useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from "../common/ComponentLoading";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UpdateName({campaignId, onClose, onUpdateCampaignName}) {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [campaignName, setCampaignName] = useState(""); // Add state for campaignName
  const [loadingText, setLoadingText] = useState("Updating your campaign...")
  const [loadingTick, setLoadingTick] = useState(false);


  async function onSubmit(event) {
    event.preventDefault();
  
    if (!campaignName) {
      alert("Please fill in all fields.");
      return;
    }

    setIsLoading(true); // Start loading
  
    const docRef = doc(db, "campaign", campaignId);
    try {
      await setDoc(docRef, { name: campaignName }, { merge: true });
      // First timeout to set the loading text
      setTimeout(() => {
        setLoadingText("Updated");
        setLoadingTick(true);
        onUpdateCampaignName(campaignName); // This updates the parent component's state

        // Second timeout to execute the onClose function after an additional delay
        setTimeout(() => {
          onClose();
        }, 1000); // Adjust the delay for the onClose as needed
      }, 1000);
    } catch (error) {
      console.error("Error updating title:", error);
    }
  }
  
  if (isLoading) {
    return <Loading text={loadingText} loadingTick={loadingTick} />; // Show loading component while inserting data
  }

  return (
    <>
<div className='relative h-full'>
    <h3 className="text-base font-semibold leading-6 text-gray-900 ">Name your campaign</h3>
    <p className="text-sm text-gray-500">You can change this later</p>


<form className="mt-3" id="campaignName" onSubmit={onSubmit}>
  <div className="w-full">
    <label htmlFor="campaignName" className="sr-only">
      Name
    </label>
    <input
      type="text" // Corrected type
      name="campaignName"
      id="campaignName" // Corrected ID
      maxLength={50}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      placeholder="Campaign Name"
      value={campaignName} 
      onChange={(e) => setCampaignName(e.target.value)} // Update campaignName state on change
    />
  </div>
 
</form>

        {/* Positioning the submit button at the bottom of the screen */}
   <div className="absolute bottom-0 left-0 w-full pb-2">
   <button
  type="submit"
  form="campaignName"
  className={`w-full flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus:ring-2 focus:ring-offset-2 ${
    !campaignName ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-600'
  }`}
  disabled={!campaignName}
>
  Update
</button>

      </div>
      </div>
    </>
  )
}
