import PropTypes from 'prop-types';
import { useUser } from '../userContext'; // Adjust the import path based on where you define the UserContext

export default function PlanLevel({ plan, onLinkClick }) {
  const { user, currentPlan } = useUser();

  if (!user) {
    return <div>Loading...</div>; // Display loading or some fallback UI if user is not available
  }

  if (plan !== 'free') {
    return null; // Don't render anything if the plan is not 'free'
  }

  const content = "Upgrade for advanced customisation and AI features.";
  const buttonText = "Upgrade now";
  const buttonAction = () => {
    onLinkClick('/plan'); // Use the callback function to navigate and close the sidebar
  };

  return (
    <div className="shadow rounded-lg bg-indigo-600 mb-1">
      <div className="p-4">
        <h3 className="text-sm font-semibold leading-6 text-white">
          <span className='capitalize'>{currentPlan}</span> plan
        </h3>
        <div className="mt-1 text-xs text-white">
          {content}
        </div>
        <div className="mt-3">
          <button
            type="button"
            className="inline-flex items-center text-center w-full rounded-md bg-white px-3 py-2 text-xs font-semibold text-indigo-700 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={buttonAction}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

PlanLevel.propTypes = {
  plan: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};