

            import { BarsArrowUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

export default function Heading({ title, showSort }) {
  return (
    <div className="flex items-center mt-10 justify-between pb-5">
      <h3 className="text-xl font-semibold leading-6 text-gray-900">{title}</h3>
      {showSort && (
        <div className="flex items-center">
          <button
            type="button"
            className="relative inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <BarsArrowUpIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            Most recent
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />

          </button>
        </div>
      )}
    </div>
  );
}