import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null); // Initialize as null
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        try {
          await currentUser.getIdToken(true);
          const decodedToken = await currentUser.getIdTokenResult();
          const plan = decodedToken.claims.stripeRole || 'free';
          console.log('Decoded Token:', decodedToken.claims.stripeRole);
          setCurrentPlan(plan);
        } catch (error) {
          console.error("Error checking plan status:", error);
        }
      } else {
        if (location.pathname !== '/games') {
          navigate("/login");
        }
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [navigate, location.pathname]);

  const isFreeUser = currentPlan === 'free';

  return { user, loading, currentPlan, isFreeUser };
};

export default useAuth;