import React, { useEffect, useRef } from 'react';

const FormInput = ({
  type = "text",
  label,
  subtitle,
  name,
  onChange,
  placeholder,
  rows,
  options,
  maxLength = 50,
  mandatory = false,
  style = "mt-5" // New prop for custom styles
}) => {
  const hasValue = options[name] && options[name].trim().length > 0;

  // Ref for the textarea
  const textareaRef = useRef(null);

  // Adjust the height of the textarea based on content
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height to auto to shrink if necessary
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
    }
  }, [options[name]]); // Dependency on content

  return (
    <div className={`${style}`}>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {mandatory && <span className={hasValue ? 'text-gray-500' : 'text-red-500'}>*</span>} {label}
      </label>
      {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>} {/* Display subtitle if provided */}
      <div className="mt-1">
        {type === "textarea" ? (
          <textarea
            id={name}
            name={name}
            rows={1} // Set to 1 initially to support auto-sizing
            maxLength={maxLength}
            value={options[name] || ''}
            onChange={onChange}
            required={mandatory}
            className="block w-full text-md sm:text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 resize-none"
            placeholder={placeholder}
            ref={textareaRef} // Attach ref here
          />
        ) : (
          <input
            type={type}
            id={name}
            name={name}
            value={options[name] || ''}
            maxLength={maxLength}
            onChange={onChange}
            required={mandatory}
            className="block w-full text-md sm:text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
            placeholder={placeholder}
          />
        )}
      </div>
    </div>
  );
};

export default FormInput;