import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayIcon } from '@heroicons/react/20/solid';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import StandardBanner from './StandardBanner';
import Loading from "../common/DefaultLoading";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCampaigns() {
      try {
        // Fetch campaigns with communityShared=true
        const q = query(collection(db, 'campaign'), where('communityShared', '==', true));
        const querySnapshot = await getDocs(q);
        const records = [];
        querySnapshot.forEach((doc) => {
          records.push({ id: doc.id, ...doc.data() });
        });
        setCampaigns(records);
      } catch (error) {
        console.error('Error fetching campaigns: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCampaigns();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className='py-4'>
        <StandardBanner
          header={"Community Shared"}
          subheader={'Campaigns shared by other users. Share your own using the additional options dropdown on your campaign.'}
          border={true}
        />
      </div>
      <div className='px-5 sm:px-8 py-5'>
        {campaigns.length > 0 ? (
          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {campaigns.map((campaign) => (
              <li key={campaign.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 ">
                    <div className="flex items-center space-x-2">
                      <h3 className="text-sm font-medium text-gray-900 break-words">{campaign.options.title}</h3>
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {campaign.gameTypeDisplayValue}
                      </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-500 break-words">{campaign.options.intro}</p>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200 bg-gray-50 border-t">
                    <div className="flex w-0 flex-1">
                      <button
                        onClick={() => navigate(`/play/${campaign.id}`)}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-1 rounded-bl-lg border border-transparent py-3 text-sm font-semibold text-gray-900"
                      >
                        <PlayIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                        Play
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-sm text-gray-500 p-2">No campaigns shared.</p>
        )}
      </div>
    </>
  );
}