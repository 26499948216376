import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, ArrowLeftIcon, TrashIcon } from '@heroicons/react/20/solid';
import { statuses } from '../data/StatusColours';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CampaignHeader({ title, name, status, actions = [], onStatusClick }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="border-b border-gray-200 py-4 bg-white">
        <div className="flex items-center px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-gray-500 hover:text-gray-700 focus:outline-none mr-2"
          >
            <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <h1 className="text-sm text-indigo-600 font-semibold leading-6 text-gray-500">
            Campaign Overview
          </h1>
        </div>
      </div>
      <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-white px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
        <div className="flex items-center gap-x-3 w-full justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex items-center gap-x-2">
              <span
                onClick={onStatusClick}
                className={classNames(
                  statuses[status],
                  'inline-block rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset capitalize cursor-pointer'
                )}
              >
                {status}
              </span>
              <h1 className="flex gap-x-3 text-base leading-7 min-w-0">
                <span className="font-semibold text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap">{title}</span>
              </h1>
            </div>
            <p className="mt-2 text-xs leading-6 text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">{name}</p>
          </div>
          <Menu as="div" className="relative inline-block text-left ml-auto">
            <div>
              <MenuButton className="inline-flex items-center gap-x-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <EllipsisVerticalIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
              </MenuButton>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {actions.map((action, index) => (
                    <MenuItem key={index}>
                      {({ active }) => (
                        action.link ? (
                          <a
                            href={action.link}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {action.name}
                          </a>
                        ) : (
                          <button
                            onClick={action.action}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : action.name.toLowerCase() === 'delete' ? 'text-red-600' : 'text-gray-700',
                              'block w-full px-4 py-2 text-left text-sm flex items-center'
                            )}
                          >
                            {action.name.toLowerCase() === 'delete' && (
                              <TrashIcon className="h-4 w-4 mr-2 text-red-600" aria-hidden="true" />
                            )}
                            {action.name}
                          </button>
                        )
                      )}
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </>
  );
}