import React, { useState } from 'react';
import { updateCampaignStep } from '../common/Database';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Embed = ({ campaignId }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopy = async (href, index) => {
    try {
      await navigator.clipboard.writeText(href); // Copy href to clipboard
      await updateCampaignStep(campaignId, 4); // Await the updateCampaignStep function
      console.log('Campaign step updated to 4 and href copied to clipboard');
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 3000); // Remove "Copied!" message after 3 seconds
    } catch (error) {
      console.error('Error copying href to clipboard or saving to database:', error);
    }
  };

  const platforms = [
    {
      name: 'Social media',
      description: 'Link directly from Instagram, Facebook, LinkedIn etc.',
      icon: faInstagram,
      href: `https://app.smilesflow.com/play/${campaignId}`,
      displayText: 'Copy embed code for social media'
    },
    {
      name: 'Website/blog',
      description: (
        <ul className="list-disc list-inside">
          <li>Copy the code below.</li>
          <li>Open your blog editor and switch to the HTML view.</li>
          <li>Paste the code where you want the iframe to appear in your post.</li>
          <li>Save and publish your post. The iframe will display an interactive game for your readers.</li>
        </ul>
      ),
      icon: faDesktop,
      href: `
        <!-- The iframe container centers the iframe and ensures it is responsive -->
        <style>
        .iframe-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 600px; /* Adjust height as needed */
            padding: 0;
            margin: 0;
            box-sizing: border-box;
        }

        /* The iframe is made responsive and takes up the full width and height of the container */
        .responsive-iframe {
            width: 100%;
            height: 100%;
            border: none;
        }

        /* Media query to adjust iframe size on smaller screens */
        @media (max-width: 768px) {
            .responsive-iframe {
                width: 100%;
                height: 100%;
            }
        }
        </style>

        <!-- Iframe container for embedding the game -->
        <div class="iframe-container">
            <!-- Iframe displaying the game. Adjust the height or width if needed -->
            <iframe class="responsive-iframe" src="https://app.smilesflow.com/play/${campaignId}" allow="web-share"></iframe>
        </div>`,
      displayText: 'Copy embed code for website/blog'
    }
  ];

  return (
    <>
      <div className='relative h-full'>
        <div>
          <ul role="list" className="divide-y divide-gray-100">
            {platforms.map((platform, index) => (
              <li key={platform.name} className="flex flex-col items-start gap-x-6 pb-5 bg-gray-50 rounded-lg p-4 mb-5">
                <div className="flex items-center w-full gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{platform.name}</p>
                    <div className="mt-1 text-xs leading-5 text-gray-500">{platform.description}</div>
                  </div>
                </div>
                <div className="w-full mt-4 ">
                  <span
                    onClick={() => handleCopy(platform.href, index)}
                    className="inline-flex items-center cursor-pointer w-full rounded-lg bg-indigo-50 px-4 py-1.5 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600 transition-all duration-300 hover:bg-indigo-100 break-words"
                  >
                    {copiedIndex === index ? (
                      <>
                        <FontAwesomeIcon icon={faCheck} className="mr-2 text-green-500" />
                        Copied to clipboard!
                      </>
                    ) : (
                      platform.displayText
                    )}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Embed;