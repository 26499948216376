import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Determine if the app is in development mode
const isDevelopment = process.env.NODE_ENV === 'development';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  isDevelopment ? (
    <React.StrictMode>
        <App />
    </React.StrictMode>
  ) : (
      <App />
  )
);