import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Logo from "../images/logo.png";
import AlertError from "./PermissionDenied";
import Loading from "../common/ComponentLoading";
import { SquareLoader } from "react-spinners";

const Signup = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [notice, setNotice] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loadingText, setLoadingText] = useState("Creating account...");
    const [loadingTick, setLoadingTick] = useState(false);

   // Added useEffect hook to check if the user is already logged in
   useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            if (user.emailVerified) {
                navigate("/");
            } else {
                navigate("/verify");
            }
        }
    });

    return () => unsubscribe();
}, [navigate]);

    useEffect(() => {
        setNotice("");
    }, [firstName, email, password, confirmPassword]);

    const isFormValid = firstName && email && password && confirmPassword;

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return 'The email address is already in use by another account.';
            case 'auth/invalid-email':
                return 'The email address is not valid.';
            case 'auth/operation-not-allowed':
                return 'Email/password accounts are not enabled.';
            case 'auth/weak-password':
                return 'The password is too weak.';
            case 'auth/user-disabled':
                return 'The user account has been disabled by an administrator.';
            case 'auth/user-not-found':
                return 'No user found with this email.';
            case 'auth/wrong-password':
                return 'The password is invalid.';
            default:
                return 'Sorry, something went wrong. Please try again.';
        }
    };

    const signupWithUsernameAndPassword = async (e) => {
        e.preventDefault();

        if (password === confirmPassword) {
            setIsProcessing(true);
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    email: user.email,
                    firstName: firstName,
                    createdAt: new Date(),
                });

                await sendEmailVerification(user);
                setIsProcessing(false);
                setIsLoading(true);

                // Display "Creating account..." for 2 seconds
                setTimeout(() => {
                    setLoadingText("Account created!");
                    setLoadingTick(true);

                    // Display "Account created" for 2 seconds
                    setTimeout(() => {
                        setIsLoading(false);
                        navigate("/verify");
                    }, 2000);
                }, 2000);

            } catch (error) {
                const errorMessage = getErrorMessage(error.code);
                setNotice(errorMessage);
                setIsProcessing(false);
                console.error("Error signing up: ", error);
            }
        } else {
            setNotice("Passwords don't match. Please try again.");
            setIsProcessing(false);
        }
    };

    if (isLoading) {
        return <Loading text={loadingText} loadingTick={loadingTick} spinnerOverride={SquareLoader} />;
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
         <div className="sm:mx-auto sm:w-full sm:max-w-sm">
    <a href="https://www.smilesflow.com" target="_blank" rel="noopener noreferrer">
        <img
            alt="SmilesFlow"
            src={Logo}
            className="mx-auto h-8 w-auto"
        />
    </a>
    
    <p className="mt-5 text-center text-sm leading-6 text-gray-500">
        Already got an account?{' '}
        <a href="/login" className="font-semibold text-indigo-600 hover:text-indigo-500">
            Sign in
        </a>
    </p>
</div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={signupWithUsernameAndPassword} method="POST" className="space-y-6">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">First name</label>
                        <div className="mt-2">
                            <input
                                id="first-name"
                                name="first-name"
                                type="text"
                                autoComplete="given-name"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                required
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="off"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">Confirm password</label>
                        <div className="mt-2">
                            <input
                                id="confirm-password"
                                name="confirm-password"
                                type="password"
                                autoComplete="off"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {notice && 
                        <AlertError text={notice} dashboardButton={false}/>
                    }

                    <div>
                        <button
                            type="submit"
                            className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ${isFormValid ? 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'bg-gray-400 cursor-not-allowed'}`}
                            disabled={!isFormValid || isProcessing}
                        >
                            {isProcessing ? "Please wait..." : "Get started free"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Signup;