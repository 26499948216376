import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../userContext';
import Loading from "../common/DefaultLoading";
import { CheckIcon } from '@heroicons/react/20/solid';

const steps = [
  { name: 'Create account', description: 'Your SmilesFlow journey has began', href: '#', status: 'complete' },
  { name: 'View game types', description: 'Check out the different game types available', href: '/games', status: 'upcoming' },
  { name: 'Create campaign', description: 'Create your first campaign using the editor', href: 'create', status: 'upcoming' },
  { name: 'Embed', description: 'Set your campaign live and embed onto your socials', href: '/campaigns', status: 'upcoming' },
  { name: 'Monitor', description: 'Review your campaigns performance', href: '/campaigns', status: 'upcoming' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example({ createCampaign }) {
  const { user, loading, introStepNumber, updateIntroStepNumber } = useUser();
  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }

  const handleStepClick = (stepIdx, href) => {
    if(href=="create"){
      createCampaign();
      return;
    }
    navigate(href);
    if (stepIdx === 1) {  // Check if it's the second step (index 1)
      updateIntroStepNumber(stepIdx + 2);  // Update to next step
    }
    
    else if (stepIdx === 4) {  // Check if it's the monitor step (index 4)
      updateIntroStepNumber(6);  // Mark as complete
      navigate('/campaigns');
    }
  };

  const handleMarkAsComplete = () => {
    updateIntroStepNumber(6);
    navigate('/campaigns');
  };

  if (introStepNumber >= 6) {
    return null; // Hide the component once step is 6 or greater
  }

  return (
    <>
        <div className="bg-gray-50 border-b border-gray-100 mb-3 px-4 sm:px-6 py-4">
      <div className="mt-5 mb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Let's get started</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Welcome to SmilesFlow. We are here to help you convert from your social media through virality and sharing amongst friends.
          </p>
        </div>
        <div className="mt-5 text-sm leading-6">
  
      
   
      <nav aria-label="Progress">
        <ol role="list" className="overflow-hidden py-5">
          {steps.map((step, stepIdx) => {
            let status = 'upcoming';
            if (stepIdx + 1 < introStepNumber) {
              status = 'complete';
            } else if (stepIdx + 1 === introStepNumber) {
              status = 'current';
            }

            return (
              <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                {status === 'complete' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600" aria-hidden="true" />
                    ) : null}
                    <div
                      className="group relative flex items-start cursor-pointer"
                      onClick={() => handleStepClick(stepIdx, step.href)}
                    >
                      <span className="flex h-9 items-center">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                          <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </div>
                  </>
                ) : status === 'current' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                    ) : null}
                    <div
                      className="group relative flex items-start cursor-pointer"
                      onClick={() => handleStepClick(stepIdx, step.href)}
                      aria-current="step"
                    >
                      <span className="flex h-9 items-center" aria-hidden="true">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                          <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-indigo-600">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                    ) : null}
                    <div
                      className="group relative flex items-start cursor-pointer"
                      onClick={() => navigate(step.href)}
                    >
                      <span className="flex h-9 items-center" aria-hidden="true">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                          <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-gray-500">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </div>
                  </>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
      {introStepNumber === 5 && (
        <div className="mt-4 flex justify-center">
          <button
            className="inline-flex items-center text-xs text-gray-600 hover:text-gray-700 "
            onClick={handleMarkAsComplete}
          >
            Mark as complete
          </button>
        </div>
        
      )}
           </div>
      </div>
    </div>
    </>
  );
}