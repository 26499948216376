import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowTopRightOnSquareIcon, LockClosedIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { gameTypes } from '../data/GameTypes'; // Import the gameTypes array
import StandardBanner from './StandardBanner';
import Slideover from "./Slideover";
import NewCampaign from "./NewCampaign";
import useAuth from './useAuth';
import DefaultLoading from "../common/DefaultLoading"; // Import a loading component

export default function Games() {
  const navigate = useNavigate();
  const [isSlideoverOpen, setIsSlideoverOpen] = useState(false);
  const { user, loading, currentPlan, isFreeUser } = useAuth();
  const [campaigns, setCampaigns] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstTwoImagesLoaded, setFirstTwoImagesLoaded] = useState(false);
  const [imageLoadCount, setImageLoadCount] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const scrollContainerRef = useRef(null);

  // Calculate the total number of images to load
  const imagesToLoad = useRef(gameTypes.reduce((acc, game) => {
    return acc + 1 + (game.additionalImages ? game.additionalImages.length : 0);
  }, 0)); // Use ref to store the total number of images

  const handleImageLoad = () => {
    setImageLoadCount((prevCount) => {
      const newCount = prevCount + 1;
      // If the first two images have loaded, set firstTwoImagesLoaded to true
      if (newCount >= 2 && !firstTwoImagesLoaded) {
        setFirstTwoImagesLoaded(true);
      }
      return newCount;
    });
  };

  const handleImageError = () => {
    console.log('Image failed to load');
    setImageLoadCount((prevCount) => {
      const newCount = prevCount + 1;
      // If the first two images have loaded, set firstTwoImagesLoaded to true
      if (newCount >= 2 && !firstTwoImagesLoaded) {
        setFirstTwoImagesLoaded(true);
      }
      return newCount;
    });
  };

  const toggleSlideover = () => {
    setIsSlideoverOpen(!isSlideoverOpen);
  };

  const handleCreate = (gameType, hasAccess) => {
    if (user) {
      if (hasAccess) {
        searchParams.set('gameType', gameType);
        setSearchParams(searchParams);
        toggleSlideover();
      } else {
        navigate('/plan');
      }
    } else {
      navigate('/register');
    }
  };

  const slideoverTitle = "New campaign";

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handlePreviousImage = (gameId) => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = { ...prevIndex, [gameId]: (prevIndex[gameId] || 0) - 1 };
      if (newIndex[gameId] < 0) {
        newIndex[gameId] = 0;
      }
      return newIndex;
    });
  };

  const handleNextImage = (gameId, imagesLength) => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = { ...prevIndex, [gameId]: (prevIndex[gameId] || 0) + 1 };
      if (newIndex[gameId] >= imagesLength) {
        newIndex[gameId] = imagesLength - 1;
      }
      return newIndex;
    });
  };
  return (
    <>
      <div className='py-4'>
        <StandardBanner
          header={"Game Library"}
          subheader={'All of our available game types'}
          border={true}
        />
      </div>
      <div className="relative p-6">
        {!firstTwoImagesLoaded && (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-75">
            <DefaultLoading />
          </div>
        )}
        <div className="hidden">
          {gameTypes.map((game) =>
            [game.imageUrl, ...(game.additionalImages || [])].map((image, index) => (
              <img
                key={index}
                alt=""
                src={image}
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{ display: 'none' }}
              />
            ))
          )}
        </div>
        {firstTwoImagesLoaded && (
          <ul role="list" className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
            {gameTypes.map((game) => {
              const hasAccess = !isFreeUser || game.role === 'free';
              const images = [game.imageUrl, ...(game.additionalImages || [])];
              const currentIndex = currentImageIndex[game.id] || 0;
              return (
                <li key={game.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
                  <div className="relative flex space-x-4 overflow-x-auto sm:hidden" ref={scrollContainerRef}>
                    {images.map((image, index) => (
                      <img
                        key={index}
                        alt=""
                        src={image}
                        className="aspect-[4/5] w-56 flex-none rounded-2xl object-cover"
                      />
                    ))}
                  </div>
                  <div className="relative hidden sm:block">
                    <img
                      alt=""
                      src={images[currentIndex]}
                      className="aspect-[4/5] w-56 flex-none rounded-2xl object-cover"
                    />
                    {game.additionalImages && game.additionalImages.length > 0 && (
                      <>
                        {currentIndex > 0 && (
                          <button
                            type="button"
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-1 shadow-md hover:bg-opacity-100"
                            onClick={() => handlePreviousImage(game.id)}
                          >
                            <ChevronLeftIcon className="h-5 w-5 text-gray-800" />
                          </button>
                        )}
                        {currentIndex < images.length - 1 && (
                          <button
                            type="button"
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-1 shadow-md hover:bg-opacity-100"
                            onClick={() => handleNextImage(game.id, images.length)}
                          >
                            <ChevronRightIcon className="h-5 w-5 text-gray-800" />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="max-w-xl flex-auto">
                    <span className={classNames(
                      game.role === 'premium' ? 'bg-yellow-400 text-yellow-800' : 'bg-indigo-100 text-indigo-800',
                      'inline-flex items-center rounded-md px-2 py-1 mb-3 text-xs font-medium ring-1 ring-inset capitalize',
                      game.role === 'premium' ? 'ring-yellow-400' : 'ring-indigo-100'
                    )}>
                      {game.role}
                      {!hasAccess && (
                        <LockClosedIcon className="ml-1 h-3 w-3" aria-hidden="true" />
                      )}
                    </span>
                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{game.name}</h3>
                    <p className="text-base leading-7 text-gray-600">{game.description}</p>
                    <ul role="list" className="mt-3 flex flex-col gap-y-2">
                      <li>
                        <a href={`/play/${game.exampleId}`} target={"_blank"} className="inline-flex items-center text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Example</span>
                          Example: {game.exampleName}
                          <ArrowTopRightOnSquareIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <button
                          type="button"
                          className={classNames(
                            'mt-3 inline-flex w-full sm:w-auto items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                            user ? (hasAccess ? 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600' : 'bg-gray-400 hover:bg-gray-500 focus-visible:outline-gray-600') : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                          )}
                          onClick={() => handleCreate(game.id, user && hasAccess)}
                        >
                          <span className="sr-only">{user ? (hasAccess ? 'Create Campaign' : 'Upgrade to access') : 'Get started today'}</span>
                          {user ? (hasAccess ? 'Create' : 'Upgrade to access') : 'Get started today'}
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {user && (
        <Slideover isOpen={isSlideoverOpen} onClose={toggleSlideover} title={slideoverTitle}>
          <NewCampaign />
        </Slideover>
      )}
    </>
  );
      };