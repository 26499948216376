import { collection, setDoc, updateDoc, getDoc, doc } from "firebase/firestore";
import { db } from '../firebase';
import uuid from 'react-uuid';

// Fetch game document
export const fetchGame = async (id) => {
  try {
    const docRef = doc(db, "campaign", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error;
  }
};

// Fetch user profile data
export const fetchUserProfile = async (userId) => {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.error("No such document!");
    return null;
  }
};

// Create campaign event
export const createCampaignEvent = async (gameId) => {
  const campaignEvent = {
    session: uuid(),
    game_id: gameId,
    game_start: new Date(),
    game_end: null,
    duration: null,
    score: null,
    shared: false,
  };

  try {
    const eventRef = doc(collection(db, "campaignEvent"));
    await setDoc(eventRef, campaignEvent);
    return eventRef;
  } catch (error) {
    console.error("Error creating event:", error);
    throw error;
  }
};

// Update campaign event
export const updateCampaignEvent = async (eventRef, gameResults) => {
  try {
    const eventDataSnap = await getDoc(eventRef);
    if (eventDataSnap.exists()) {
      const eventData = eventDataSnap.data();
      const gameEnd = new Date();
      const duration = gameEnd - eventData.game_start.toDate(); // Convert Firestore Timestamp to Date
      const durationInSeconds = duration / 1000; // Converts milliseconds to seconds
      const score = calculateScore(gameResults);

      // Update the document
      await updateDoc(eventRef, {
        game_end: gameEnd,
        duration: durationInSeconds,
        score: score,
        shared: false,
      });
    }
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

// Function to calculate the score based on game results
const calculateScore = (gameResults) => {
  // Implement your score calculation logic here
  let score = 0;
  for (let result of gameResults) {
    if (result.isCorrect) {
      score += 1; // Increment score for each correct answer
    }
  }
  return score;
};

// Update campaign step
export const updateCampaignStep = async (campaignId, newStep) => {
  try {
    const campaignRef = doc(db, "campaign", campaignId);
    const campaignSnap = await getDoc(campaignRef);

    if (campaignSnap.exists()) {
      const campaignData = campaignSnap.data();
      if (newStep > campaignData.step || !campaignData.step) {
        await updateDoc(campaignRef, { step: newStep });
        console.log(`Campaign step updated to ${newStep}`);
      } else {
        console.log(`Current step (${campaignData.step}) is greater than or equal to ${newStep}`);
      }
    } else {
      console.error("No such campaign document!");
    }
  } catch (error) {
    console.error("Error updating campaign step:", error);
    throw error;
  }
};